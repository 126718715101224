@use "./Breakpoints" as *;
@use "./Font" as *;

.sign-up-container {
    background-color: #fff;
    width: 100%;
    height: 100%;
    @include sm {
            flex-direction: column;
        }

    .right {
        width: 50%;
        display: flex;
        justify-content: center;
        height: 100vh;
        @include sm {
            width: 100%;
        }

        .active {
            text-decoration: underline;
            font-weight: 700;
        }

        .forgot-password {
            margin-top: 15px;

            span {
                text-decoration: underline;

                &:hover {
                    font-weight: 700;
                }
            }
        }

        .login-button {
            background-color: #F4ACB7;
            width: 80%;
            max-width: 542px;
            margin-top: 15px;
            box-sizing: border-box;
            padding: 15px 20px;
            color: white;
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            text-align: center;

            &:hover {
                background-color: #FF5D8F;
            }
        }

        .login-container {
            align-items: center;
        }

        .decor {
            display: flex;
            align-items: center;
            width: 80%;
            max-width: 542px;
            column-gap: 10px;

            .line {
                border-bottom: 1px solid black;
                width: 50%;
                height: 1px;
            }
        }

        .field-wrapper {
            width: 80%;
            max-width: 542px;

            span {
                color: red;
                font-weight: 700;
            }
        }

        input {
            padding: 10px 20px;
            border-radius: 10px;
            margin-bottom: 15px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #BAB0B0;
        }

        input[type=submit] {
            background-color: #F4ACB7;
            border: 0;
            color: white;
            font-weight: 700;
            font-size: 16px;
            max-width: 542px;
            width: 80%;
            box-sizing: border-box;

            &:hover {
                background-color: #FF5D8F;
            }
        }

        .tab-container {
            justify-content: center;
            column-gap: 30px;
            font-size: 20px;
            padding-bottom: 20px;
        }

        .wrapper {
            padding: 120px 15px;
            width: 100%;
            @include sm {
                padding: 30px 15px;
            }

            .flex-header {
                font-size: 40px;
                font-weight: 700;
                text-align: center;
                padding-bottom: 20px;
            }
        }
    }

    .left {
        background-color: #F4ACB7;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0 25px 25px 0;
        @include sm {
            width: 100%;
            border-radius: 0 0 0 0;
            padding: 30px 0;
        }

        .wrapper {
            padding-bottom: 40px;
            padding-right: 30px;
        }

        .flex-header {
            font-weight: 700;
            font-size: 50px;
            color: white;
            padding-bottom: 30px;
        }

        .flex-subtitle {
            font-weight: 700;
            font-size: 30px;
            color: white;
        }

        .flex-body {
            color: white;
            font-size: 24px;
        }

    }
}


// 2nd Sign Up Page , Employee
.create-profile-container {
    background-color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 30px 50px 120px 50px;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    min-height: 90vh;
    position: relative;
    @include sm {
       padding:  30px 20px 120px 20px;
    }
    .back-btn {
        position: absolute;
        bottom: 30px;
        background-color: #F4ACB7;
        border: 0;
        width: 24%;
        padding: 10px 30px;
        border-radius: 10px;
        font-weight: 700;
        transition: background-color 0.2s ease-in;
        &:hover {
            background-color: #FF8FAB;
            color: #fff;

        }
    }
    .overlay-container {
            width: 90%;
            height: 90%;
            background-color: white;
            position: absolute;
            top: 5%;
            left: 5%;
            z-index: 3;
            border-radius: 15px;
            @include sm {
                height: max-content;
            }
        .text-container {
                display: flex;
                justify-content: flex-start;
                column-gap: 15px;
                row-gap: 20px;
                flex-wrap: wrap;
                width: 700px;
                @include lg {
                    width: 90%;
                }
        
                .text-item {
                    input {
                        display: none;
                    }
        
        
                    span {
                        position: relative;
                        z-index: 3;
                        background-color: #FFCAD4;
                        box-sizing: border-box;
                        padding: 4px 15px;
                        border-radius: 50px;
                        font-weight: 500;
        
                        &.activated {
                            background-color: #FF8FAB;
                        }
        
                        &.not-activated {
                            background-color: #FFCAD4;
                        }
                    }
                }
            }
            .top {
                display: flex;
                padding: 10px 50px;
                align-items: center;
                column-gap: 20px;
                @include sm {
                    padding: 20px 20px 10px 44px;
                }
    
               
    
                button {
                    border: none;
                    border-radius: 50%;
                    min-width: 50px;
                    min-height: 50px;
                   
                }
                .back-button {
                    font-size: 16px;
                }
            }
            .done-button-container {
                    margin: auto;
                    box-sizing: border-box;
                    padding: 10px 50px 30px 50px;
                    display: flex;
                    justify-content: center;
                button {
                    background-color: #F4ACB7;
                    padding: 7px 30px;
                    border-radius: 100px;
                    box-sizing: border-box;
                    border: 0;
                    font-weight: 700;
                    font-size: 18px;
                    transition: background-color 0.1s ease-in;
                    width: 200px;
                    margin-top: 30px;
                    &:hover {
                        background-color: #FF5D8F;
                        color: #fff;
                    }
                    @include sm {
                        width: 100%;
                    }
                }
                
            }
            .wrapper {
                padding: 10px 50px;
          
    
                .header-3 {
                    padding: 20px 0;
                }
            }
        }
                   .overlay {
                       width: 100%;
                       height: 100%;
                       position: fixed;
                       background-color: grey;
                       opacity: 0.5;
                       top: 0;
                       left: 0;
        
                   }
        
                   .white-container {
                       width: 70%;
                       height: 50%;
                       background-color: #fff;
                       position: absolute;
                       border-radius: 20px;
                       z-index: 2;
                        left: 15%;
                        top: 20%;
                        @include lg{
                            height: auto;
                        }
        
                       .row {
                           width: 80%;
                           display: flex;
                           justify-content: center;
                           margin: 20px auto;
                           column-gap: 50px;
                            @include lg {
                                flex-direction: column;
                                align-items: center;
                                row-gap: 30px;
                            }
        
                           &.last-row {
                               position: absolute;
                               bottom: 10px;
                               left: 10%;
                               @include lg {
                                position: relative;
                                bottom: -20px;
                                left: 0;
                                padding-bottom: 40px;
                               }
                           }
        
                           .row-item {
                            display: flex;
                            flex-direction: column;
                            width: 280px;
                            @include sm {
                                max-width: 280px;
                                width: 100%;
                            }
                           
                            select,
                                input {
                                    background-color: #FFCAD4;
                                    border-radius: 10px;
                                    box-sizing: border-box;
                                    border: 1px solid black;
                                    padding: 10px 15px;
                                    width: 100%;
                                }
                               label {
                                   text-align: center;
                               }
        
                               .past-experience-button {
                                   margin: 0 15px;
                                   border: 0;
                                   background-color: #FFCAD4;
                                   border-radius: 10px;
                                   padding: 10px 15px;
                                   font-weight: 600;
                                   font-size: 16px;
                                    @include lg {
                                        margin: 0 0;
                                    }
                                   &:hover {
                                       background-color: #FF5D8F;
                                       color: white;
                                   }
                                
                               }
                           }
                       }
                   }
                   
    .grid-container {
            display: grid;
            grid-template-columns: (repeat(3, 1fr));
            row-gap: 30px;
            column-gap: 30px;
            margin-top: 20px;
            @include md {
                grid-template-columns: 1fr 1fr;
            }
            @include sm {
                grid-template-columns: 1fr;
            }
            .bestfive-container {
                display: flex;
                column-gap: 5px;
                row-gap: 5px;
                flex-wrap: wrap;
            }

            .descriptor-button {
                border: none;
                background-color: #FFCAD4;
                padding: 5px 30px;
                width: max-content;
                border-radius: 40px;
                font-size: 18px;
            }

            .add-more {
                background-color: #FFCAD4;
                border: 1px solid black;
                color: #6E6E6E;
                border-radius: 10px;
                text-align: start;
                width: 100%;
                padding: 10px 15px;
            }
                
            .row-item {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            font-size: $font-sm;
            @include sm {
                    width: 100%;
                }
            .add-tag {
                border: 0;
                width: 100%;
                background-color: #F4ACB7;
                font-size: 18px;
                padding: 5px 0;
                margin-top: 10px;
                border-radius: 10px;

                &:hover {
                    background-color: #FF5D8F;
                    color: #fff;

                }
            }
            .tag-container{ 
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                column-gap: 5px;
                padding-top: 10px;
                .tag-item {
                    background-color: #F4ACB7;
                    padding: 2px 10px 2px 15px;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    &:hover {
                        color: white;
                    }
                }
            }
        
                
    
            .industry-container {
                justify-content: space-between;
                align-items: center;
                background-color: #FFCAD4;
                border: 1px solid black;
                padding: 5px 15px;
                border-radius: 10px;
    
                .remove {
                    height: 100%;
    
                    input {
                        display: none;
                    }

                   .tag-container {
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 5px;
                    column-gap: 5px;
                    padding-bottom: 7px;
                    .tag-item {
                        box-sizing: border-box;
                        padding: 3px 10px 3px 15px;
                        background-color: #FFCAD4;
                        border-radius: 20px;
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        &:hover {
                            background-color: #F4ACB7;
                        }
                    }
                   } 
                    .industry-container {
                        justify-content: space-between;
                        align-items: center;
                        background-color: #FFCAD4;
                        border: 1px solid black;
                        padding: 5px 15px;
                        border-radius: 10px;
            
                        .remove {
                            height: 100%;
            
                            input {
                                display: none;
                            }
            
                            svg {
                                font-size: 25px;
                            }
                        }

                    }
                }
            }
            
                select,
                input {
                    background-color: #FFCAD4;
                    border-radius: 10px;
                    box-sizing: border-box;
                    border: 1px solid black;
                    padding: 10px 15px;
                    width: 100%;
                    font-size: $font-sm;
                }
        
                .descriptor-item {
                    background-color: #FFCAD4;
                    box-sizing: border-box;
                    padding: 4px 15px;
                    border-radius: 50px;
                    font-weight: 500;
                    width: max-content;
                }
                .file-item {
                    display: flex;
                    background-color: #FFCAD4;
                    border: 1px solid black;
                    margin-bottom: 10px;;
                    justify-content: space-between;
                    padding: 10px 20px 10px 20px;
                    border-radius: 10px;
                    column-gap: 5px;
                    &:hover {
                        .right {
                            color: white;
                        }
                    }
                }
            }
        }

    .create-account-btn {
        background-color: #F4ACB7;
        padding: 10px 30px;
        border-radius: 10px;
        border: 0;
        position: absolute;
        bottom: 25px;
        right: 50px;
        width: 24%;
        min-width: 150px;
        font-weight: 700;
        transition: background-color 0.2s ease-in;
        @include sm {
            right: 15px;
        }

        &:hover {
            background-color: #FF8FAB;
            color:#fff;

        }
    }

    .first-row,
    .second-row,
    .third-row {
        display: flex;
        justify-content: space-between;
        column-gap: 100px;
        padding-top: 15px;
        @include sm {
            flex-direction: column;
        }


     
    }

    .second-row {
        .bestfive-container {
            display: flex;
            column-gap: 5px;
            row-gap: 5px;
            flex-wrap: wrap;
        }

        .descriptor-button {
            border: none;
            background-color: #FFCAD4;
            padding: 5px 10px;
            width: 100px;
            border-radius: 40px;
        }

        .add-more {
            background-color: #FFCAD4;
            border: 1px solid black;
            color: #6E6E6E;
            border-radius: 10px;
            text-align: start;
            width: 100%;
            padding: 10px 15px;
        }

        .overlay {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: grey;
            opacity: 0.5;
            top: 0;
            left: 0;

        }

       

        .overlay-container {
            width: 90%;
            height: 90%;
            background-color: white;
            position: absolute;
            top: 5%;
            z-index: 3;

            .top {
                display: flex;
                padding: 10px 50px;
                padding-right: 50%;
                align-items: center;
                column-gap: 20px;

                button {
                    border: none;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    font-size: 22px;
                }
            }

            .wrapper {
                padding: 10px 50px;
                padding-right: 50%;

                .header-3 {
                    padding: 20px 0;
                }
            }
        }

        .text-container {
            display: flex;
            justify-content: flex-start;
            column-gap: 15px;
            row-gap: 20px;

            flex-wrap: wrap;

            .text-item {
                input {
                    display: none;
                }


                span {
                    position: relative;
                    z-index: 3;
                    background-color: #FFCAD4;
                    box-sizing: border-box;
                    padding: 4px 15px;
                    border-radius: 50px;
                    font-weight: 500;

                    &.activated {
                        background-color: #FF8FAB;
                    }

                    &.not-activated {
                        background-color: #FFCAD4;
                    }
                }
            }
        }
    }
}

// Sign Up Employer stage 2
.sign-up-employer {
    background-color: #fff;
    height: 100%;
    display: flex;
    @include sm {
        flex-direction: column;
    }
    

    .left {
        background-color: #F4ACB7;
        width: 50%;
        border-radius: 0 25px 25px 0;
        @include sm {
            width: 100%;
            border-radius: 0;
        }

        .text-container {
            width: max-content;
            margin: auto;
            padding-top: 150px;
            @include sm {
                padding: 30px 0;
            }
        }

        .decor-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 70px;
            @include sm {
                display: none;
            }

            .one {
                border: 3px solid #C9184A;
            }

            .two {
                border: 3px solid #C9184A;
            }

            .line {
                border-right: 3px solid #C9184A;
                width: 1px;
                height: 50px;
            }

            .line-two {
                border-right: 3px solid #C9184A;
                width: 1px;
                height: 50px;
            }
            .three {
                border: 3px solid #FF5D8F;
            }

            .circle {
                background-color: white;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 22px;

            }
        }

        .flex-header {
            font-size: 40px;
            font-weight: 700;
            padding-bottom: 20px;
            color: #fff;
        }

        .flex-subtitle {
            font-weight: 700;
            font-size: 30px;
            color: white;
        }
    }

    .right {
        width: 50%;
        min-height: 100vh;
        @include sm {
            width: 100%;
        }

        .img-container {
            border: 1px dotted;
            display: flex;
            background-color: rgba(255, 255, 255, 0.3);
            justify-content: center;
            box-sizing: border-box;
            padding: 45px 0;
            width: 200px;
            margin: auto;
            border-radius: 15px;
        }

        img {
            width: 120px;
        }

        .sub-text {
            padding: 10px 0;
            font-weight: 700;
            color: #939090;
        }

        .sub-text-2 {
            padding: 10px 0;
            font-weight: 700;
            color: #939090;
            width: 200px;
            margin: auto;
        }

        .header {
            font-size: 40px;
            font-weight: 700;
            padding-bottom: 20px;
            color: #F4ACB7;
            text-align: center;
            padding-top: 50px;
        }

        .container {
            background-color: #F0EBD8;
            box-sizing: border-box;
            margin: 20px 40px;
            padding: 20px 20px;
            border-radius: 15px;
        }

        .title {
            font-size: 30px;
            font-weight: 700;
            padding-bottom: 20px;
        }

        .text {
            font-weight: 700;
        }
    }
}

.form-container {
    background-color: #fff;
    height: 80vh;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 30px;

    .title {
        font-size: 28px;
        font-weight: 700;
    }

    .field-container {
        display: flex;
        flex-direction: column;

        .first-row,
        .second-row,
        .form-row {
            padding-top: 15px;
            column-gap: 100px;

            .form-item {
                display: flex;
                flex-direction: column;
                width: 33%;
                row-gap: 10px;

                select,
                input,
                textarea {
                    background-color: #FFCAD4;
                    border: 1px solid black;
                    border-radius: 7px;
                    padding: 3px 10px;
                }

                div[contenteditable] {
                    background-color: #FFCAD4;
                    border: 1px solid black;
                    border-radius: 7px;
                    padding: 3px 10px;
                    max-height: 200px;
                    overflow: auto;
                }
            }
        }

        .last-row {
            justify-content: flex-end;

            .form-item {
                width: calc(33% - 62px);
            }

            button {
                padding: 10px 0px;
                width: 100%;
                background-color: #F4ACB7;
                border: 1px solid black;
                border-radius: 8px;
            }
        }
    }
}