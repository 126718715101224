@use "./Breakpoints" as *;
@use "./Font" as *;

.flex-container {
    margin: auto;
    width: 90%;
    display: flex;
    padding-top: 30px;
    column-gap: 30px;
    justify-content: space-between;
    @media (max-width: 860px) {
        flex-direction: column;
        row-gap: 40px;
        align-items: center;
    }

    .left {
        width: 410px;
        @media (max-width: 860px) {
            width: 100%;
        }

        .find-a-job-container {
            box-sizing: border-box;
            padding: 10px 30px 20px 30px;
            border-radius: 15px;
            background-color: #fff;
            @include sm {
                padding: 10px;
            }
            .category-items {
                display: flex;
                flex-wrap: wrap;
                column-gap: 5px;
                row-gap: 5px;
                padding-bottom: 10px;
            }
            .category-item {
                display: flex;
                flex-direction: row;
                background-color: #F4ACB7;
                padding: 0;
                border-radius: 100px;
                width: max-content;
                align-items: center;
                box-sizing: border-box;
                padding: 0 15px;
                font-size: $font-sm;
                column-gap: 5px;
                &:hover {
                    color: white;
                    background-color: #9D8189;
                }
                &>div {
                    padding: 0;
                }

                }
            .title {
                font-size: $font-lg;
            }
            .sub-title {
                font-size: $font-md;
            }
            div {
                padding: 5px 0;
            }
            .day-filter-draft {
                display: flex;
                justify-content: center;
            }

            .clear-date-button-container {
                .clear-date-button {
                        border: none;
                        background-color: #F4ACB7;
                        padding: 10px 15px;
                        border-radius: 100px;
                        width: 100%;
                        transition: background-color 0.2s ease-in, color 0.2s ease-in;
                        font-size: 16px;
                        font-weight: 600;
                    &:hover {
                        background-color: #FF8FAB;
                        color: white;
                    }
                }
            }

            #homepage-location, #category {
                width: 100%;
                background-color: #FFF;
                padding: 5px;
                font-size: $font-sm;
            }

            .location-container {
                display: flex;
                column-gap: 5px;
                flex-wrap: wrap;
                row-gap: 8px;

                .location-item {
                    background-color: #F4ACB7;
                    padding: 0;
                    border-radius: 100px;
                    input {
                        display: none;
                    }

                    &:hover {
                        background-color: #9D8189;
                        color: white;
                    }

                    label {
                        padding: 3px 15px;
                        font-size: $font-sm;
                        display: flex;
                        column-gap: 5px;
                        align-items: center;
                    }
                }
            }

        

            // input {
            //     display: none;
            // }


            // span {
            //     position: relative;
            //     z-index: 3;
            //     font-size: $font-sm;

            //     &:hover {
            //         &::before {
            //             background-color: #FF8FAB;
            //         }
            //     }
            // }

            // span::before {
            //     content: "";
            //     position: absolute;
            //     height: 60px;
            //     width: 60px;
            //     background-color: #FFCAD4;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     border-radius: 50%;
            //     z-index: -1;
            //     transition: background-color 0.3s;

            // }

            // input:checked+span::before {
            //     background-color: #F0EBD8;
            // }

            swiper-container {
                width: 100%;
                .swiper-button-next,
                    .swiper-button-prev {
                        color: black !important;
                    }
            }
            .day-filter,
            .hours-filter {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 10px;

                & label {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

            }
        }

        .banner-container {
            overflow: hidden;
            max-height: 300px;
            display: flex;
            border-radius: 15px;
            margin-top: 30px;

            img {
                object-fit: cover;
                width: 100%;
            }

        }
    }

    .right {
        min-width: 640px;
        height: min-content;
        row-gap: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        column-gap: 20px;
        @include xl {
            min-width: 0;
            grid-template-columns: 1fr;
        }
        @media (max-width: 860px) {
            max-width: 100%;
            width: 100%;
            justify-content: space-between;
            column-gap: 10px;
        }

       

        a {
            color: initial;
            text-decoration: none;
        }

        .flex {
            .tags {
                display: flex;
                max-width: 70%;
                flex-wrap: wrap;
                row-gap: 7px;
                column-gap: 5px;
                padding-top: 10px;

                .tag {
                    display: flex;
                    align-items: center;
                    background-color: #9D8189;
                    box-sizing: border-box;
                    border-radius: 50px;
                    padding: 0 10px;
                    color: #fff;
                    min-height: 24px;
                    font-size: $font-sm;
                }
            }
            .wage {
                font-size: $font-sm;
            }
        }
       
        .job-item {
            background-color: #fff;
            border-radius: 13px;
            min-width: 340px;
            width: 100%;
            box-sizing: border-box;
            padding: 15px 20px;
            flex-direction: column;
            display: flex;
            row-gap: 7px;
            transition: box-shadow 0.4s;
        
            &:hover {
                box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
            }
            @media (max-width: 1330px) {
                width: 100%;
            }

            .text {
                width: 230px;
            }

            .image {
                width: 70px;
                height: 70px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .job-title {
                font-weight: 600;
                font-size: $font-md;
            }
            .company-name, .location, .languages, .date, .hours, .time {
                font-size: $font-sm;
            }

          
        }
    }
}