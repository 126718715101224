
@use "./Breakpoints" as *;
@use "./Font" as *;
// User Profile 
.profile-user-container {
    .back-home {
        box-sizing: border-box;
        width: 90%;
        margin: auto;
        padding: 15px 0px;

        button {
            background: none;
            border: 0px;
            font-size: 20px;
            font-weight: 700;

            &:hover {
                color: #fff;
            }
        }
    }

    .profile-container {
        width: 90%;
        margin: auto;
        column-gap: 30px;
        @include lg {
            flex-direction: column;
            align-items: center;
            row-gap: 30px;
        }

        .left {
            background-color: white;
            border-radius: 15px;
            box-sizing: border-box;
            padding: 30px;
            max-width: 300px;
            width: 23%;
            height: max-content;
            @include lg {
                max-width: none;
                width: 100%;
            }

            .image {
                display: flex;
                justify-content: center;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                margin: auto;
            }

            .star-rating {
                display: flex;
                justify-content: center;
                font-size: 25px;
                flex-direction: column;
                .star.filled {
                        color: #FF8FAB;
                        /* Filled star color */
                    }
                    div {
                        display: flex;
                        justify-content: center;
                    }
            }
            .reviews {
                font-size: 18px;
            }

            .header {
                font-size: 26px;
                font-weight: 700;
                text-align: center;
            }

            .sub-header {
                font-weight: 700;
                font-size: 24px;
                text-decoration: underline;
                padding-top: 20px;
            }

            .grid-container {
                padding-bottom: 10px;
            }

            .grey-body {
                font-size: $font-sm;
                padding-top: 10px;
                font-weight: 600;
            }

            .body-text {
                font-size: $font-sm;
                word-wrap: break-word;
            }
            .grid-container {
                @include lg {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
                @include md {
                    grid-template-columns: 1fr 1fr;
                }
                
                @include sm {
                    grid-template-columns: 1fr
                }
              
            }
            .edit-button-container {
                display: flex;
                justify-content: center;
                button {
                    background-color:#FFCAD4;
                    padding: 10px 30px;
                    border: 0;
                    width: 100%;
                    border-radius: 100px;
                    font-size: 18px;
                    transition: background-color 0.2s ease-in;
                    &:hover {
                        background-color: #FF8FAB;
                        color: white;
                    }


                }
            }
            .descriptor-container {
                display: flex;
                flex-wrap: wrap;
                row-gap: 10px;
                font-weight: 500;
                column-gap: 10px;
                padding: 10px 0 30px 0;

                .descriptor-item {
                    box-sizing: border-box;
                    width: max-content;
                    background-color: #FF8FAB;
                    padding: 4px 15px;
                    border-radius: 100px;
                    font-size: $font-sm;
                    font-weight: 400;

                }

            }

            .edit-button {
                width: 90%;
                margin: auto;
                font-weight: 700;
                font-size: 20px;
                border: 0px;
                background-color: #FFCAD4;
                border-radius: 50px;
                padding: 5px 0;
                display: block;

                &:hover {
                    background-color: #FF5D8F;
                    color: #fff;
                }
            }
        }

        .right {

            width: 80%;
            @include lg {
                width: 100%;
            }

            .upcoming-container {
                background-color: #fff;
                flex-direction: column;
                border-radius: 15px;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 30px 0 40px 0;
                margin-bottom: 30px;

                .body-text {
                    margin-top: 5px;
                    font-size: $font-sm;
                }

                .header {
                    font-weight: 700;
                    font-size: $font-md;

                }

            }

            .listing-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                margin-top: 10px;



                .listing-item {
                    background-color: #FFCAD4;
                    margin: auto;
                    width: 80%;
                   
                    display: flex;
                    height: auto;
                    padding: 10px 30px;
                    border-radius: 15px;
                    box-sizing: border-box;
                    justify-content: space-between;
                    @include md {
                        flex-wrap: wrap;
                        row-gap: 10px;
                    }
                    @include sm {
                        flex-direction: column;
                        padding: 20px 30px;
                    }
                    

                    .line-decor {
                        width: 1px;
                        height: 100%;
                        border-left: 1px solid black;
                    }

                    .sub-header {
                        font-weight: 700;
                        font-size: 18px;
                    }

                    .left-side {
                        column-gap: 15px;
                        width: 40%;
                        align-items: center;
                        @include md {
                            width:100%;
                            padding-right: 7px;
                        }
                        @include sm {
                            width: 100%;
                            flex-direction: column;
                            align-items: flex-start;
                            row-gap: 10px;
                        }

                        .logo-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            overflow: hidden;
                            height: 55px;
                            width: 55px;
                            flex-grow: 0;
                            flex-shrink: 0;
                        }
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                           
                            
                        }


                    }

                    .middle {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        position: relative;
                        width: 25%;
                        @include md {
                            width: 50%;
                            padding-left: 70px;
                        }
                        @include sm {
                            width: 80%;
                            padding-left: 0;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            left: -20px;
                            width: 1px;
                            height: 100%;
                            border-left: 2px solid black;
                            @include md {
                                left: -5%;
                                display: none;
                              
                            }
                            @include sm {
                                display: none;
                            }
                        }
                    }

                    .right-side {
                        flex-direction: column;
                        justify-content: center;
                        row-gap: 5px;
                        position: relative;
                        width: 25%;
                        max-width: 188px;
                        @include md {
                            width: 50%;
                        }
                        @include sm {
                            max-width: none;
                            flex-direction: column;
                            column-gap: 5px;
                            width: 100%;
                            div, a {
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: -30px;
                            width: 1px;
                            height: 100%;
                            border-left: 2px solid black;
                            @include md {
                                left: -10%;
                                display: none;
                               
                            }
                            @include sm {
                                display: none;
                            }
                        }

                        button {
                            border: 0px;
                            width: 100%;
                            background-color: #fff;
                            font-weight: 700;
                            font-size: 16px;
                            padding: 5px 30px;
                            text-align: center;
                            border-radius: 100px;

                            &:hover {
                                background-color: #FF5D8F;
                                color: #fff;
                            }
                            

                        }

                    }
                }
            }
        }
    }
.job-listing-container {
    display: flex;
    background: #FFCAD4;
    border-radius: 10px;
    padding: 20px 10px;
    width: 80%;
    margin: auto;
    justify-content: space-around;
    box-sizing: border-box;
    @include sm {
        flex-direction: column;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 50px;
        height: 50px;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .job-title-container {
        font-size: 22px;
        width: 50%;
        padding-left: 20px;
        align-self: center;
        text-align: center;
        @include sm {
            padding-left: 0;
        }
       


    }

    .date-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        width: 50%;
        @include sm {
                width: 100%;
            }

        .title {
            font-size: 20px;
        }

        .date {
            font-size: 20px;
        }
    }

}
}

