.language-component {
    position: absolute;
    right: 10px;
    top: 3px;
    display: flex;
    color: black;

    div {
        padding: 5px 20px;
        display: flex;
        align-items: center;
    }

    .active {
        background-color: rgba(245, 40, 145, 0.4);
        border-radius: 10px;
    }
    
}



.Translation-i18-Component {
    display: absolute;
}