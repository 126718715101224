 @use "./Breakpoints" as *;
 @use "./Font" as *;


 .UserProfileCard {
    min-width: 300px;
    .left {
            background-color: white;
            border-radius: 15px;
            box-sizing: border-box;
            padding: 30px;
            max-width: 300px;
            width: 100%;
            height: max-content;

            @include lg {
                max-width: none;
                width: 100%;
            }

            .image {
                display: flex;
                justify-content: center;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                margin: auto;
            }

            .star-rating {
                display: flex;
                justify-content: center;
                font-size: 25px;
                flex-direction: column;

                .star.filled {
                    color: #FF8FAB;
                    /* Filled star color */
                }
                .reviews {
                    font-size: 18px;
                }
                div {
                    display: flex;
                    justify-content: center;
                }
            }

            .header {
                font-size: 26px;
                font-weight: 700;
                text-align: center;
            }

            .sub-header {
                font-weight: 700;
                font-size: 24px;
                text-decoration: underline;
                padding-top: 20px;
            }

            .grid-container {
                padding-bottom: 10px;
            }

            .grey-body {
                font-size: $font-sm;
                padding-top: 10px;
                font-weight: 600;
            }

            .body-text {
                font-size: $font-sm;
                word-wrap: break-word;
            }

            .grid-container {
                @include lg {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                @include md {
                    grid-template-columns: 1fr 1fr;
                }

                @include sm {
                    grid-template-columns: 1fr
                }

            }

            .edit-button-container {
                display: flex;
                justify-content: center;

                button {
                    background-color: #FFCAD4;
                    padding: 10px 30px;
                    border: 0;
                    width: 100%;
                    border-radius: 100px;
                    font-size: 18px;
                    transition: background-color 0.2s ease-in;

                    &:hover {
                        background-color: #FF8FAB;
                        color: white;
                    }


                }
            }

            .descriptor-container {
                display: flex;
                flex-wrap: wrap;
                row-gap: 10px;
                font-weight: 500;
                column-gap: 10px;
                padding: 10px 0 30px 0;

                .descriptor-item {
                    box-sizing: border-box;
                    width: max-content;
                    background-color: #FF8FAB;
                    padding: 4px 15px;
                    border-radius: 100px;
                    font-size: $font-sm;
                    font-weight: 400;

                }

            }

            .edit-button {
                width: 90%;
                margin: auto;
                font-weight: 700;
                font-size: 20px;
                border: 0px;
                background-color: #FFCAD4;
                border-radius: 50px;
                padding: 5px 0;
                display: block;

                &:hover {
                    background-color: #FF5D8F;
                    color: #fff;
                }
            }
        }
 }
