$font-sm: 20px;
$font-md: 24px;
$font-lg: 28px;

.App{
    font-family: "Times", "San Francisco", "Noto Sans HK", "Helvetica Neue", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
}




