.workshop-details-container {
    .details-container {
        .left {
            width: 100%;
        }
    }
.section-container-1 {
        display: flex;
        margin-top: 30px;
        column-gap: 20px;

        .img-container {
            border-radius: 50%;
            overflow: hidden;
            width: 70px;
            height: 70px;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .title {
                font-size: 28px;
                font-weight: 600;
            }

            .sub-title {
                font-size: 20px;
            }
        }
    }
    .section-container-2 {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .section-container-3 {
        font-size: 20px;
        margin-top: 20px;
    }
    .section-container-4 {
        margin-top: 20px;
        .title {
            font-size: 28px;
            font-weight: 600;
        }
        .highlight-container {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-top: 15px;
            font-size: 18px;
        }
    }
    .section-container-5 {
        margin-top: 20px;
        padding-bottom: 50px;
        .title {
            font-size: 28px;
            font-weight: 600;
        }
        .instructor-card {
            display: flex;
            column-gap: 15px;
            margin-top: 15px;
            .img-container {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
            }
            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .name {
                    font-size: 22px;
                    font-weight: 600;
                }
                .description {
                    font-size: 18px;
                }
            }
        }
    }
}


.workshop-details-container {
    .details-container {
        .job-summary {
            .title {
                margin-bottom: 10px;
                font-size: 28px;
                font-weight: 600;
            }
        }
    }
}