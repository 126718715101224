@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.landing .highlight {
  color: brown;
}
.landing .English {
  font-size: 20;
  color: blue;
}
.landing .Chinese {
  font-size: 20;
  color: red;
}
.landing .Employer {
  font-size: 20;
  color: orange;
}
.landing .Worker {
  font-size: 20;
  color: green;
}
.landing .body {
  font-size: 20;
  font-weight: 500;
  color: black;
  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.landing .button-box-language-gap {
  height: 25px;
  width: 20%;
  background-color: transparent;
}
.landing .button-box-language {
  width: 100px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  float: right;
  z-index: 3;
  background-color: grey;
  border-radius: 30px;
  margin-left: 10px;
}
.landing .toggle-btn-language {
  width: 50px;
  padding: 10px 10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
  color: white;
  font-weight: bold;
}
.landing .button-box-type {
  width: 210px;
  height: 39px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  float: right;
  z-index: 3;
  background-color: grey;
  border-radius: 30px;
}
.landing .button-border-box {
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  /* Set the navbar to fixed position */
  top: 30px;
  right: 10px;
  z-index: 3;
}
.landing .mobile-button-border-box {
  display: none;
}
@media (max-width: 780px) {
  .landing .mobile-button-border-box {
    display: block;
  }
}
.landing .toggle-btn-type {
  width: 100px;
  padding: 10px 10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  text-align: center;
  position: relative;
  color: white;
  font-weight: bold;
}
.landing #btnLang {
  Left: 0;
  top: 0;
  position: absolute;
  width: 50px;
  height: 39px;
  background: rgb(248, 229, 232);
  border-radius: 30px;
  transition: 0.5s;
  border-color: black;
}
.landing #btnType {
  Left: 0;
  top: 0;
  position: absolute;
  width: 110px;
  height: 39px;
  background: rgb(248, 229, 232);
  border-radius: 30px;
  transition: 0.5s;
  border-color: black;
}
.landing .navbar {
  overflow: hidden;
  background-color: pink;
  position: fixed;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  height: 100px;
  z-index: 2;
}
.landing .flex-container-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 50px;
  box-sizing: border-box;
  position: relative;
  z-index: 4;
  height: 100%;
  align-items: center;
}
@media (max-width: 780px) {
  .landing .flex-container-banner {
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.landing .flex-container-banner-1 {
  color: white;
}
@media (max-width: 780px) {
  .landing .flex-container-banner-1 {
    z-index: 4;
  }
}
.landing .flex-container-banner-1 .desktop-menu {
  display: flex;
  align-items: center;
  padding-right: 30px;
}
@media (max-width: 780px) {
  .landing .flex-container-banner-1 .desktop-menu {
    display: none;
  }
}
.landing .flex-container-banner-1 .ham-menu {
  display: none;
}
.landing .flex-container-banner-1 .ham-menu span {
  display: none;
  background-color: white;
  width: 100%;
  height: 5px;
}
@media (max-width: 780px) {
  .landing .flex-container-banner-1 .ham-menu {
    display: none;
    position: absolute;
    left: 30px;
    top: 35px;
    justify-content: space-between;
    flex-direction: column;
    height: 30px;
    width: 50px;
  }
}
.landing .flex-container-banner-1 .off-screen-toggle {
  position: fixed;
}
.landing .flex-container-banner-1 .off-screen-toggle.active {
  width: 100vh;
  height: 100vh;
  z-index: 4;
}
.landing .flex-container-banner-1 .off-screen-toggle.inactive {
  width: 0;
  height: 0;
}
.landing .flex-container-banner-1 .off-screen-toggle .cross {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50px;
  left: 20px;
}
.landing .flex-container-banner-1 .off-screen-toggle .cross span {
  position: absolute;
  border-radius: 15px;
  display: block;
  width: 100%;
  height: 5px;
  background-color: white;
}
.landing .flex-container-banner-1 .off-screen-toggle .cross .cross-1 {
  transform: rotate(45deg);
}
.landing .flex-container-banner-1 .off-screen-toggle .cross .cross-2 {
  transform: rotate(-45deg);
}
.landing .flex-container-banner-1 .off-screen-menu {
  display: flex;
  background-color: rgba(147, 225, 216, 0.99);
  height: 100vh;
  width: 80%;
  max-width: 450px;
  position: fixed;
  top: 0;
  left: -450px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: 0.3s ease;
}
.landing .flex-container-banner-1 .off-screen-menu.active {
  left: 0px;
}
.landing .flex-container-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 5%;
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .landing .flex-container-main {
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
  }
}
@media (max-width: 780px) {
  .landing .flex-container-main.reverse-column {
    flex-direction: column-reverse;
  }
}
.landing .flex-container-main-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}
.landing .content-word-box {
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 50px 0px; */
  padding-top: 60px;
  padding-bottom: 50px;
  width: 500px;
  height: 450px;
}
@media (max-width: 1024px) {
  .landing .content-word-box {
    padding: 0;
    padding-top: 50px;
    width: 80%;
    height: auto;
  }
}
.landing .content-word-box-small {
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  /* padding: 50px 0px; */
  width: 300px;
}
.landing .flex-container-words {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: center;
  /* margin-left: 300px;
  margin-right: 500px; */
  justify-content: center;
}
.landing .flex-container-words-content-4 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.landing .word-banner {
  font-size: 20px;
  font-weight: bold;
  padding: 25px 20px;
  display: none;
}
.landing .main-header {
  font-size: 30px;
  box-sizing: border-box;
}
.landing .main-header-small {
  font-size: 30px;
  box-sizing: border-box;
}
.landing .main-body {
  font-size: 20px;
}
.landing .main-body-small {
  font-size: 20px;
  width: 100px;
}
.landing .main {
  font-size: 50px;
  padding: 50px 50px;
}
.landing .logo {
  width: 100px;
  height: 100px;
}
@media (max-width: 780px) {
  .landing .logo {
    display: none;
  }
}
.landing .Log-in-sign-up {
  background-color: pink;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
}
.landing .Log-in-sign-up:hover {
  background-color: rgb(228, 126, 143);
}
.landing .platformTemplate {
  box-sizing: border-box;
  width: 550px;
  height: 400px;
  padding-top: 60px;
  float: right;
  justify-content: center;
}
@media (max-width: 1024px) {
  .landing .platformTemplate {
    padding: 0;
    padding-bottom: 40px;
    height: auto;
    width: 70%;
  }
}
.landing .main-2 {
  background-color: pink;
  width: 100%;
  height: 500px;
}
@media (max-width: 1280px) {
  .landing .main-2 {
    height: auto;
  }
}
.landing .main-2 .flex-container-main-2 {
  display: flex;
  color: white;
  width: 80%;
  justify-content: space-between;
  padding-bottom: 40px;
}
@media (max-width: 780px) {
  .landing .main-2 .flex-container-main-2 {
    flex-direction: column;
  }
}
.landing .main-3 {
  width: 100%;
  height: auto;
  padding: 30px 0 100px 0;
}
@media (max-width: 1280px) {
  .landing .main-3 {
    padding-top: 10px;
    padding-bottom: 50px;
  }
}
.landing .main-4 {
  background-color: pink;
  width: 100%;
  padding: 50px 0 70px 0;
}
.landing .main-header-2 {
  /* width:1440px; */
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: white;
}
.landing .main-header-3 {
  /* width:1440px; */
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: black;
}
.landing .main-header-4 {
  /* width:1440px; */
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: white;
}
.landing .main-header-2-highlight {
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: brown;
}
.landing .main-header-3-highlight {
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: brown;
}
.landing .main-header-4-highlight {
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: brown;
}
.landing .flex-container-main-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 60px;
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  color: white;
  justify-content: center;
}
@media (max-width: 1280px) {
  .landing .flex-container-main-2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 780px) {
  .landing .flex-container-main-2 {
    grid-template-columns: 1fr;
    width: 50%;
  }
}
.landing .flex-container-main-2 .main-3-content {
  color: black;
}
@media (max-width: 1280px) {
  .landing .flex-container-main-2 .main-3-content {
    width: 80%;
    justify-self: center;
  }
}
.landing .main-4 .grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
@media (max-width: 780px) {
  .landing .main-4 .grid-container {
    grid-template-columns: 1fr;
  }
}
.landing .main-4 .grid-container img {
  padding: 0;
  justify-self: center;
  align-self: center;
}
.landing .main-4 .content-4 {
  display: flex;
  justify-content: center;
  column-gap: 15%;
  padding-left: 70px;
}
@media (max-width: 780px) {
  .landing .main-4 .content-4 {
    flex-direction: column;
    padding-left: 0;
    align-items: center;
  }
}
.landing .main-4 .main-4-content {
  width: 350px;
}
@media (max-width: 1280px) {
  .landing .main-4 .main-4-content {
    width: 40%;
  }
}
@media (max-width: 780px) {
  .landing .main-4 .main-4-content {
    width: 80%;
  }
}
.landing .flex-container-words {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: center;
  margin-bottom: 30px;
  /* margin-left: 300px;
  margin-right: 500px; */
  justify-content: center;
}
.landing .main-2-image {
  width: auto;
  height: 150px;
  fill: white;
}
.landing .image-flexible-job {
  width: auto;
  height: 175px;
  padding-bottom: -20px;
  fill: white;
  position: center;
}
.landing .main-content-1A {
  margin-top: -10px;
}
.landing .main-2-content {
  /* box-sizing: border-box; */
  text-align: center;
}
.landing .main-2-employer {
  background-color: white;
  width: 100%;
  height: 100px;
}
.landing .main-header-2-employer {
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: black;
}
.landing .main-header-2-highlight-employer {
  box-sizing: border-box;
  padding: left 300px;
  padding-top: 20px;
  padding-right: 10px;
  text-align: center;
  color: brown;
}
.landing .main-header {
  font-size: 30px;
  box-sizing: border-box;
}
.landing .main-4-content-header-left {
  color: grey;
  padding-left: 0px;
}
.landing .main-4-content-header-right {
  color: grey;
  padding-left: 0px;
}
.landing .main-4-content-content-left {
  font-size: 20px;
  width: 350px;
}
@media (max-width: 1280px) {
  .landing .main-4-content-content-left {
    margin-right: 0;
    width: auto;
  }
}
.landing .main-4-content-content-right {
  font-size: 20px;
}
.landing .main-4-image {
  width: 200px;
  height: 200px;
  fill: white;
  padding: 150px 100px 0px 150px;
  margin-right: -50px;
}
@media (max-width: 1280px) {
  .landing .main-4-image {
    margin-right: 0;
  }
}
.landing .main-content-3-image {
  font-size: 6em;
  color: brown;
  margin-top: -10px;
  margin-bottom: -10px;
}
.landing .main-content-3-content {
  font-size: 1.5em;
}

html {
  background-color: #F4ACB7;
  height: 100%;
}

#root {
  height: 100%;
}

.chinese {
  font-family: "Noto Sans HK", "San Francisco";
}

.bold {
  font-weight: 700;
}

.swiper-button-prev {
  color: black;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: grey;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 2;
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.header-1 {
  font-size: 28px;
  font-weight: 700;
}

.header-2 {
  font-size: 24px;
  font-weight: 700;
}

.header-3 {
  font-size: 20px;
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.nav {
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  background-color: #FFF;
  align-items: center;
  border-radius: 15px;
  padding: 15px 30px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 40px;
}
.nav .left {
  display: flex;
  align-items: center;
  position: relative;
}
.nav .left .ham-menu {
  display: none;
}
.nav .left .ham-menu span {
  display: block;
  background-color: black;
  width: 100%;
  height: 4px;
}
@media (max-width: 780px) {
  .nav .left .ham-menu {
    display: flex;
    position: absolute;
    top: 7px;
    left: 60px;
    justify-content: space-between;
    flex-direction: column;
    height: 30px;
    width: 50px;
  }
}
.nav .left .off-screen-menu {
  display: flex;
  background-color: rgba(147, 225, 216, 0.99);
  height: 100vh;
  width: 80%;
  max-width: 450px;
  position: fixed;
  top: 0;
  left: -450px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: 0.3s ease;
  color: white;
}
.nav .left .off-screen-menu.active {
  left: 0px;
  z-index: 5;
}
.nav .left .off-screen-menu .cross {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50px;
  left: 20px;
}
.nav .left .off-screen-menu .cross span {
  position: absolute;
  border-radius: 15px;
  display: block;
  width: 100%;
  height: 5px;
  background-color: white;
}
.nav .left .off-screen-menu .cross .cross-1 {
  transform: rotate(45deg);
}
.nav .left .off-screen-menu .cross .cross-2 {
  transform: rotate(-45deg);
}
.nav .left .nav-item-wrapper {
  display: flex;
}
@media (max-width: 780px) {
  .nav .left .nav-item-wrapper {
    display: none;
  }
}
.nav .left img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.nav .left .nav-item {
  padding: 0 15px;
  position: relative;
}
.nav .left .nav-item::before {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 80%;
  top: 15%;
  border-right: 2px solid #d4d4d4;
}
@media (max-width: 780px) {
  .nav .left .nav-item::before {
    display: none;
  }
}
.nav .left .nav-item:last-child::before {
  border: 0;
}
.nav .nav-item {
  font-weight: 600;
  font-size: 24px;
}
.nav .nav-item:hover {
  color: #FF8FAB;
}

.App {
  font-family: "Times", "San Francisco", "Noto Sans HK", "Helvetica Neue", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
}

.flex-container {
  margin: auto;
  width: 90%;
  display: flex;
  padding-top: 30px;
  column-gap: 30px;
  justify-content: space-between;
}
@media (max-width: 860px) {
  .flex-container {
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
  }
}
.flex-container .left {
  width: 410px;
}
@media (max-width: 860px) {
  .flex-container .left {
    width: 100%;
  }
}
.flex-container .left .find-a-job-container {
  box-sizing: border-box;
  padding: 10px 30px 20px 30px;
  border-radius: 15px;
  background-color: #fff;
}
@media (max-width: 780px) {
  .flex-container .left .find-a-job-container {
    padding: 10px;
  }
}
.flex-container .left .find-a-job-container .category-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  padding-bottom: 10px;
}
.flex-container .left .find-a-job-container .category-item {
  display: flex;
  flex-direction: row;
  background-color: #F4ACB7;
  padding: 0;
  border-radius: 100px;
  width: max-content;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 20px;
  column-gap: 5px;
}
.flex-container .left .find-a-job-container .category-item:hover {
  color: white;
  background-color: #9D8189;
}
.flex-container .left .find-a-job-container .category-item > div {
  padding: 0;
}
.flex-container .left .find-a-job-container .title {
  font-size: 28px;
}
.flex-container .left .find-a-job-container .sub-title {
  font-size: 24px;
}
.flex-container .left .find-a-job-container div {
  padding: 5px 0;
}
.flex-container .left .find-a-job-container .day-filter-draft {
  display: flex;
  justify-content: center;
}
.flex-container .left .find-a-job-container .clear-date-button-container .clear-date-button {
  border: none;
  background-color: #F4ACB7;
  padding: 10px 15px;
  border-radius: 100px;
  width: 100%;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  font-size: 16px;
  font-weight: 600;
}
.flex-container .left .find-a-job-container .clear-date-button-container .clear-date-button:hover {
  background-color: #FF8FAB;
  color: white;
}
.flex-container .left .find-a-job-container #homepage-location, .flex-container .left .find-a-job-container #category {
  width: 100%;
  background-color: #FFF;
  padding: 5px;
  font-size: 20px;
}
.flex-container .left .find-a-job-container .location-container {
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
  row-gap: 8px;
}
.flex-container .left .find-a-job-container .location-container .location-item {
  background-color: #F4ACB7;
  padding: 0;
  border-radius: 100px;
}
.flex-container .left .find-a-job-container .location-container .location-item input {
  display: none;
}
.flex-container .left .find-a-job-container .location-container .location-item:hover {
  background-color: #9D8189;
  color: white;
}
.flex-container .left .find-a-job-container .location-container .location-item label {
  padding: 3px 15px;
  font-size: 20px;
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.flex-container .left .find-a-job-container swiper-container {
  width: 100%;
}
.flex-container .left .find-a-job-container swiper-container .swiper-button-next,
.flex-container .left .find-a-job-container swiper-container .swiper-button-prev {
  color: black !important;
}
.flex-container .left .find-a-job-container .day-filter,
.flex-container .left .find-a-job-container .hours-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
}
.flex-container .left .find-a-job-container .day-filter label,
.flex-container .left .find-a-job-container .hours-filter label {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-container .left .banner-container {
  overflow: hidden;
  max-height: 300px;
  display: flex;
  border-radius: 15px;
  margin-top: 30px;
}
.flex-container .left .banner-container img {
  object-fit: cover;
  width: 100%;
}
.flex-container .right {
  min-width: 640px;
  height: min-content;
  row-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 20px;
}
@media (max-width: 1380px) {
  .flex-container .right {
    min-width: 0;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 860px) {
  .flex-container .right {
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    column-gap: 10px;
  }
}
.flex-container .right a {
  color: initial;
  text-decoration: none;
}
.flex-container .right .flex .tags {
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
  row-gap: 7px;
  column-gap: 5px;
  padding-top: 10px;
}
.flex-container .right .flex .tags .tag {
  display: flex;
  align-items: center;
  background-color: #9D8189;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 0 10px;
  color: #fff;
  min-height: 24px;
  font-size: 20px;
}
.flex-container .right .flex .wage {
  font-size: 20px;
}
.flex-container .right .job-item {
  background-color: #fff;
  border-radius: 13px;
  min-width: 340px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
  flex-direction: column;
  display: flex;
  row-gap: 7px;
  transition: box-shadow 0.4s;
}
.flex-container .right .job-item:hover {
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}
@media (max-width: 1330px) {
  .flex-container .right .job-item {
    width: 100%;
  }
}
.flex-container .right .job-item .text {
  width: 230px;
}
.flex-container .right .job-item .image {
  width: 70px;
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-container .right .job-item .image img {
  height: 100%;
  width: 100%;
}
.flex-container .right .job-item .job-title {
  font-weight: 600;
  font-size: 24px;
}
.flex-container .right .job-item .company-name, .flex-container .right .job-item .location, .flex-container .right .job-item .languages, .flex-container .right .job-item .date, .flex-container .right .job-item .hours, .flex-container .right .job-item .time {
  font-size: 20px;
}

.job-details-container,
.workshop-details-container {
  background-color: white;
  margin: 30px 50px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
}
@media (max-width: 1024px) {
  .job-details-container,
  .workshop-details-container {
    margin: 30px 30px;
  }
}
@media (max-width: 780px) {
  .job-details-container,
  .workshop-details-container {
    margin: 30px 20px;
  }
}
.job-details-container .apply-job-btn,
.workshop-details-container .apply-job-btn {
  position: absolute;
  bottom: 15px;
  right: 20px;
  border: 0;
  background-color: #FFCAD4;
  padding: 10px 50px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
}
.job-details-container .apply-job-btn:hover,
.workshop-details-container .apply-job-btn:hover {
  background-color: #FF5D8F;
  color: white;
}
.job-details-container .white-container,
.workshop-details-container .white-container {
  background-color: #fff;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 20px;
  padding-bottom: 60px;
}
@media (max-width: 1024px) {
  .job-details-container .white-container,
  .workshop-details-container .white-container {
    width: 80%;
  }
}
@media (max-width: 780px) {
  .job-details-container .white-container,
  .workshop-details-container .white-container {
    width: 90%;
  }
}
.job-details-container .white-container img,
.workshop-details-container .white-container img {
  display: block;
  margin: auto;
  padding-bottom: 30px;
  width: 70px;
  height: 70px;
}
.job-details-container .white-container .body-text,
.workshop-details-container .white-container .body-text {
  font-size: 20px;
  font-weight: 600;
}
.job-details-container .white-container .button-container,
.workshop-details-container .white-container .button-container {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 30px;
}
.job-details-container .white-container .button-container button,
.workshop-details-container .white-container .button-container button {
  border: 0;
  min-width: 150px;
  padding: 5px 40px;
  border-radius: 7px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.job-details-container .white-container .button-container button:nth-child(1),
.workshop-details-container .white-container .button-container button:nth-child(1) {
  background-color: #9D8189;
}
.job-details-container .white-container .button-container button:nth-child(2),
.workshop-details-container .white-container .button-container button:nth-child(2) {
  background-color: #F4ACB7;
}
.job-details-container .white-container .button-container button:hover,
.workshop-details-container .white-container .button-container button:hover {
  background-color: #FF5D8F;
}
.job-details-container .white-container .title,
.workshop-details-container .white-container .title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
}
.job-details-container .white-container .body-text,
.workshop-details-container .white-container .body-text {
  text-align: center;
}
.job-details-container .h1,
.workshop-details-container .h1 {
  font-size: 30px;
  font-weight: 600;
}
.job-details-container .h2,
.workshop-details-container .h2 {
  font-size: 28px;
  font-weight: 600;
}
.job-details-container .h3,
.workshop-details-container .h3 {
  font-size: 20px;
}
.job-details-container .top-container,
.workshop-details-container .top-container {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  align-items: center;
  column-gap: 10px;
}
.job-details-container .top-container button,
.workshop-details-container .top-container button {
  background-color: #343A40;
  border-radius: 50%;
  border: none;
  height: 42px;
  width: 42px;
  color: white;
}
.job-details-container .job-container,
.job-details-container .details-container,
.workshop-details-container .job-container,
.workshop-details-container .details-container {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
@media (max-width: 780px) {
  .job-details-container .job-container,
  .job-details-container .details-container,
  .workshop-details-container .job-container,
  .workshop-details-container .details-container {
    column-gap: 0;
  }
}
.job-details-container .job-container .summary-item,
.job-details-container .job-container .allowance-item,
.job-details-container .job-container .requirement-item,
.job-details-container .details-container .summary-item,
.job-details-container .details-container .allowance-item,
.job-details-container .details-container .requirement-item,
.workshop-details-container .job-container .summary-item,
.workshop-details-container .job-container .allowance-item,
.workshop-details-container .job-container .requirement-item,
.workshop-details-container .details-container .summary-item,
.workshop-details-container .details-container .allowance-item,
.workshop-details-container .details-container .requirement-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 20px;
}
.job-details-container .job-container .summary-item .icon,
.job-details-container .job-container .allowance-item .icon,
.job-details-container .job-container .requirement-item .icon,
.job-details-container .details-container .summary-item .icon,
.job-details-container .details-container .allowance-item .icon,
.job-details-container .details-container .requirement-item .icon,
.workshop-details-container .job-container .summary-item .icon,
.workshop-details-container .job-container .allowance-item .icon,
.workshop-details-container .job-container .requirement-item .icon,
.workshop-details-container .details-container .summary-item .icon,
.workshop-details-container .details-container .allowance-item .icon,
.workshop-details-container .details-container .requirement-item .icon {
  font-size: 30px;
  width: 37px;
}
.job-details-container .job-container .summary-item .text,
.job-details-container .job-container .allowance-item .text,
.job-details-container .job-container .requirement-item .text,
.job-details-container .details-container .summary-item .text,
.job-details-container .details-container .allowance-item .text,
.job-details-container .details-container .requirement-item .text,
.workshop-details-container .job-container .summary-item .text,
.workshop-details-container .job-container .allowance-item .text,
.workshop-details-container .job-container .requirement-item .text,
.workshop-details-container .details-container .summary-item .text,
.workshop-details-container .details-container .allowance-item .text,
.workshop-details-container .details-container .requirement-item .text {
  width: 70%;
}
.job-details-container .job-container .left,
.job-details-container .details-container .left,
.workshop-details-container .job-container .left,
.workshop-details-container .details-container .left {
  width: 65%;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}
@media (max-width: 1280px) {
  .job-details-container .job-container .left,
  .job-details-container .details-container .left,
  .workshop-details-container .job-container .left,
  .workshop-details-container .details-container .left {
    width: 100%;
  }
}
.job-details-container .job-container .left .job-summary,
.job-details-container .details-container .left .job-summary,
.workshop-details-container .job-container .left .job-summary,
.workshop-details-container .details-container .left .job-summary {
  margin-top: 20px;
}
.job-details-container .job-container .left .job-detail .detail-text,
.job-details-container .details-container .left .job-detail .detail-text,
.workshop-details-container .job-container .left .job-detail .detail-text,
.workshop-details-container .details-container .left .job-detail .detail-text {
  font-size: 20px;
}
@media (max-width: 780px) {
  .job-details-container .job-container .left .job-detail,
  .job-details-container .details-container .left .job-detail,
  .workshop-details-container .job-container .left .job-detail,
  .workshop-details-container .details-container .left .job-detail {
    padding-bottom: 50px;
  }
}
@media (max-width: 780px) {
  .job-details-container .job-container .left .allowance,
  .job-details-container .details-container .left .allowance,
  .workshop-details-container .job-container .left .allowance,
  .workshop-details-container .details-container .left .allowance {
    padding-bottom: 40px;
  }
}
.job-details-container .job-container .left .allowance-item,
.job-details-container .details-container .left .allowance-item,
.workshop-details-container .job-container .left .allowance-item,
.workshop-details-container .details-container .left .allowance-item {
  width: 33%;
}
.job-details-container .job-container .left .summary-items,
.job-details-container .details-container .left .summary-items,
.workshop-details-container .job-container .left .summary-items,
.workshop-details-container .details-container .left .summary-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
}
@media (max-width: 780px) {
  .job-details-container .job-container .left .summary-items,
  .job-details-container .details-container .left .summary-items,
  .workshop-details-container .job-container .left .summary-items,
  .workshop-details-container .details-container .left .summary-items {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
}
.job-details-container .job-container .left .dress-code .img-container,
.job-details-container .details-container .left .dress-code .img-container,
.workshop-details-container .job-container .left .dress-code .img-container,
.workshop-details-container .details-container .left .dress-code .img-container {
  height: 200px;
}
.job-details-container .job-container .left .dress-code .img-container swiper-container,
.job-details-container .details-container .left .dress-code .img-container swiper-container,
.workshop-details-container .job-container .left .dress-code .img-container swiper-container,
.workshop-details-container .details-container .left .dress-code .img-container swiper-container {
  height: 100%;
}
.job-details-container .job-container .left .dress-code .img-container swiper-slide,
.job-details-container .details-container .left .dress-code .img-container swiper-slide,
.workshop-details-container .job-container .left .dress-code .img-container swiper-slide,
.workshop-details-container .details-container .left .dress-code .img-container swiper-slide {
  display: flex;
  justify-content: center;
  height: 95%;
}
.job-details-container .job-container .left .dress-code .img-container img,
.job-details-container .details-container .left .dress-code .img-container img,
.workshop-details-container .job-container .left .dress-code .img-container img,
.workshop-details-container .details-container .left .dress-code .img-container img {
  height: 100%;
  border: 1px solid black;
}

.swiper-button-prev {
  color: black;
}

.sign-up-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
@media (max-width: 780px) {
  .sign-up-container {
    flex-direction: column;
  }
}
.sign-up-container .right {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 100vh;
}
@media (max-width: 780px) {
  .sign-up-container .right {
    width: 100%;
  }
}
.sign-up-container .right .active {
  text-decoration: underline;
  font-weight: 700;
}
.sign-up-container .right .forgot-password {
  margin-top: 15px;
}
.sign-up-container .right .forgot-password span {
  text-decoration: underline;
}
.sign-up-container .right .forgot-password span:hover {
  font-weight: 700;
}
.sign-up-container .right .login-button {
  background-color: #F4ACB7;
  width: 80%;
  max-width: 542px;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 15px 20px;
  color: white;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.sign-up-container .right .login-button:hover {
  background-color: #FF5D8F;
}
.sign-up-container .right .login-container {
  align-items: center;
}
.sign-up-container .right .decor {
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 542px;
  column-gap: 10px;
}
.sign-up-container .right .decor .line {
  border-bottom: 1px solid black;
  width: 50%;
  height: 1px;
}
.sign-up-container .right .field-wrapper {
  width: 80%;
  max-width: 542px;
}
.sign-up-container .right .field-wrapper span {
  color: red;
  font-weight: 700;
}
.sign-up-container .right input {
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #BAB0B0;
}
.sign-up-container .right input[type=submit] {
  background-color: #F4ACB7;
  border: 0;
  color: white;
  font-weight: 700;
  font-size: 16px;
  max-width: 542px;
  width: 80%;
  box-sizing: border-box;
}
.sign-up-container .right input[type=submit]:hover {
  background-color: #FF5D8F;
}
.sign-up-container .right .tab-container {
  justify-content: center;
  column-gap: 30px;
  font-size: 20px;
  padding-bottom: 20px;
}
.sign-up-container .right .wrapper {
  padding: 120px 15px;
  width: 100%;
}
@media (max-width: 780px) {
  .sign-up-container .right .wrapper {
    padding: 30px 15px;
  }
}
.sign-up-container .right .wrapper .flex-header {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}
.sign-up-container .left {
  background-color: #F4ACB7;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 25px 25px 0;
}
@media (max-width: 780px) {
  .sign-up-container .left {
    width: 100%;
    border-radius: 0 0 0 0;
    padding: 30px 0;
  }
}
.sign-up-container .left .wrapper {
  padding-bottom: 40px;
  padding-right: 30px;
}
.sign-up-container .left .flex-header {
  font-weight: 700;
  font-size: 50px;
  color: white;
  padding-bottom: 30px;
}
.sign-up-container .left .flex-subtitle {
  font-weight: 700;
  font-size: 30px;
  color: white;
}
.sign-up-container .left .flex-body {
  color: white;
  font-size: 24px;
}

.create-profile-container {
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px 50px 120px 50px;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  min-height: 90vh;
  position: relative;
}
@media (max-width: 780px) {
  .create-profile-container {
    padding: 30px 20px 120px 20px;
  }
}
.create-profile-container .back-btn {
  position: absolute;
  bottom: 30px;
  background-color: #F4ACB7;
  border: 0;
  width: 24%;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 700;
  transition: background-color 0.2s ease-in;
}
.create-profile-container .back-btn:hover {
  background-color: #FF8FAB;
  color: #fff;
}
.create-profile-container .overlay-container {
  width: 90%;
  height: 90%;
  background-color: white;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 3;
  border-radius: 15px;
}
@media (max-width: 780px) {
  .create-profile-container .overlay-container {
    height: max-content;
  }
}
.create-profile-container .overlay-container .text-container {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 20px;
  flex-wrap: wrap;
  width: 700px;
}
@media (max-width: 1280px) {
  .create-profile-container .overlay-container .text-container {
    width: 90%;
  }
}
.create-profile-container .overlay-container .text-container .text-item input {
  display: none;
}
.create-profile-container .overlay-container .text-container .text-item span {
  position: relative;
  z-index: 3;
  background-color: #FFCAD4;
  box-sizing: border-box;
  padding: 4px 15px;
  border-radius: 50px;
  font-weight: 500;
}
.create-profile-container .overlay-container .text-container .text-item span.activated {
  background-color: #FF8FAB;
}
.create-profile-container .overlay-container .text-container .text-item span.not-activated {
  background-color: #FFCAD4;
}
.create-profile-container .overlay-container .top {
  display: flex;
  padding: 10px 50px;
  align-items: center;
  column-gap: 20px;
}
@media (max-width: 780px) {
  .create-profile-container .overlay-container .top {
    padding: 20px 20px 10px 44px;
  }
}
.create-profile-container .overlay-container .top button {
  border: none;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
}
.create-profile-container .overlay-container .top .back-button {
  font-size: 16px;
}
.create-profile-container .overlay-container .done-button-container {
  margin: auto;
  box-sizing: border-box;
  padding: 10px 50px 30px 50px;
  display: flex;
  justify-content: center;
}
.create-profile-container .overlay-container .done-button-container button {
  background-color: #F4ACB7;
  padding: 7px 30px;
  border-radius: 100px;
  box-sizing: border-box;
  border: 0;
  font-weight: 700;
  font-size: 18px;
  transition: background-color 0.1s ease-in;
  width: 200px;
  margin-top: 30px;
}
.create-profile-container .overlay-container .done-button-container button:hover {
  background-color: #FF5D8F;
  color: #fff;
}
@media (max-width: 780px) {
  .create-profile-container .overlay-container .done-button-container button {
    width: 100%;
  }
}
.create-profile-container .overlay-container .wrapper {
  padding: 10px 50px;
}
.create-profile-container .overlay-container .wrapper .header-3 {
  padding: 20px 0;
}
.create-profile-container .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: grey;
  opacity: 0.5;
  top: 0;
  left: 0;
}
.create-profile-container .white-container {
  width: 70%;
  height: 50%;
  background-color: #fff;
  position: absolute;
  border-radius: 20px;
  z-index: 2;
  left: 15%;
  top: 20%;
}
@media (max-width: 1280px) {
  .create-profile-container .white-container {
    height: auto;
  }
}
.create-profile-container .white-container .row {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  column-gap: 50px;
}
@media (max-width: 1280px) {
  .create-profile-container .white-container .row {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
}
.create-profile-container .white-container .row.last-row {
  position: absolute;
  bottom: 10px;
  left: 10%;
}
@media (max-width: 1280px) {
  .create-profile-container .white-container .row.last-row {
    position: relative;
    bottom: -20px;
    left: 0;
    padding-bottom: 40px;
  }
}
.create-profile-container .white-container .row .row-item {
  display: flex;
  flex-direction: column;
  width: 280px;
}
@media (max-width: 780px) {
  .create-profile-container .white-container .row .row-item {
    max-width: 280px;
    width: 100%;
  }
}
.create-profile-container .white-container .row .row-item select,
.create-profile-container .white-container .row .row-item input {
  background-color: #FFCAD4;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 10px 15px;
  width: 100%;
}
.create-profile-container .white-container .row .row-item label {
  text-align: center;
}
.create-profile-container .white-container .row .row-item .past-experience-button {
  margin: 0 15px;
  border: 0;
  background-color: #FFCAD4;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .create-profile-container .white-container .row .row-item .past-experience-button {
    margin: 0 0;
  }
}
.create-profile-container .white-container .row .row-item .past-experience-button:hover {
  background-color: #FF5D8F;
  color: white;
}
.create-profile-container .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 30px;
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .create-profile-container .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 780px) {
  .create-profile-container .grid-container {
    grid-template-columns: 1fr;
  }
}
.create-profile-container .grid-container .bestfive-container {
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
}
.create-profile-container .grid-container .descriptor-button {
  border: none;
  background-color: #FFCAD4;
  padding: 5px 30px;
  width: max-content;
  border-radius: 40px;
  font-size: 18px;
}
.create-profile-container .grid-container .add-more {
  background-color: #FFCAD4;
  border: 1px solid black;
  color: #6E6E6E;
  border-radius: 10px;
  text-align: start;
  width: 100%;
  padding: 10px 15px;
}
.create-profile-container .grid-container .row-item {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 20px;
}
@media (max-width: 780px) {
  .create-profile-container .grid-container .row-item {
    width: 100%;
  }
}
.create-profile-container .grid-container .row-item .add-tag {
  border: 0;
  width: 100%;
  background-color: #F4ACB7;
  font-size: 18px;
  padding: 5px 0;
  margin-top: 10px;
  border-radius: 10px;
}
.create-profile-container .grid-container .row-item .add-tag:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.create-profile-container .grid-container .row-item .tag-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  padding-top: 10px;
}
.create-profile-container .grid-container .row-item .tag-container .tag-item {
  background-color: #F4ACB7;
  padding: 2px 10px 2px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.create-profile-container .grid-container .row-item .tag-container .tag-item:hover {
  color: white;
}
.create-profile-container .grid-container .row-item .industry-container {
  justify-content: space-between;
  align-items: center;
  background-color: #FFCAD4;
  border: 1px solid black;
  padding: 5px 15px;
  border-radius: 10px;
}
.create-profile-container .grid-container .row-item .industry-container .remove {
  height: 100%;
}
.create-profile-container .grid-container .row-item .industry-container .remove input {
  display: none;
}
.create-profile-container .grid-container .row-item .industry-container .remove .tag-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  padding-bottom: 7px;
}
.create-profile-container .grid-container .row-item .industry-container .remove .tag-container .tag-item {
  box-sizing: border-box;
  padding: 3px 10px 3px 15px;
  background-color: #FFCAD4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.create-profile-container .grid-container .row-item .industry-container .remove .tag-container .tag-item:hover {
  background-color: #F4ACB7;
}
.create-profile-container .grid-container .row-item .industry-container .remove .industry-container {
  justify-content: space-between;
  align-items: center;
  background-color: #FFCAD4;
  border: 1px solid black;
  padding: 5px 15px;
  border-radius: 10px;
}
.create-profile-container .grid-container .row-item .industry-container .remove .industry-container .remove {
  height: 100%;
}
.create-profile-container .grid-container .row-item .industry-container .remove .industry-container .remove input {
  display: none;
}
.create-profile-container .grid-container .row-item .industry-container .remove .industry-container .remove svg {
  font-size: 25px;
}
.create-profile-container .grid-container .row-item select,
.create-profile-container .grid-container .row-item input {
  background-color: #FFCAD4;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 10px 15px;
  width: 100%;
  font-size: 20px;
}
.create-profile-container .grid-container .row-item .descriptor-item {
  background-color: #FFCAD4;
  box-sizing: border-box;
  padding: 4px 15px;
  border-radius: 50px;
  font-weight: 500;
  width: max-content;
}
.create-profile-container .grid-container .row-item .file-item {
  display: flex;
  background-color: #FFCAD4;
  border: 1px solid black;
  margin-bottom: 10px;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  column-gap: 5px;
}
.create-profile-container .grid-container .row-item .file-item:hover .right {
  color: white;
}
.create-profile-container .create-account-btn {
  background-color: #F4ACB7;
  padding: 10px 30px;
  border-radius: 10px;
  border: 0;
  position: absolute;
  bottom: 25px;
  right: 50px;
  width: 24%;
  min-width: 150px;
  font-weight: 700;
  transition: background-color 0.2s ease-in;
}
@media (max-width: 780px) {
  .create-profile-container .create-account-btn {
    right: 15px;
  }
}
.create-profile-container .create-account-btn:hover {
  background-color: #FF8FAB;
  color: #fff;
}
.create-profile-container .first-row,
.create-profile-container .second-row,
.create-profile-container .third-row {
  display: flex;
  justify-content: space-between;
  column-gap: 100px;
  padding-top: 15px;
}
@media (max-width: 780px) {
  .create-profile-container .first-row,
  .create-profile-container .second-row,
  .create-profile-container .third-row {
    flex-direction: column;
  }
}
.create-profile-container .second-row .bestfive-container {
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
}
.create-profile-container .second-row .descriptor-button {
  border: none;
  background-color: #FFCAD4;
  padding: 5px 10px;
  width: 100px;
  border-radius: 40px;
}
.create-profile-container .second-row .add-more {
  background-color: #FFCAD4;
  border: 1px solid black;
  color: #6E6E6E;
  border-radius: 10px;
  text-align: start;
  width: 100%;
  padding: 10px 15px;
}
.create-profile-container .second-row .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: grey;
  opacity: 0.5;
  top: 0;
  left: 0;
}
.create-profile-container .second-row .overlay-container {
  width: 90%;
  height: 90%;
  background-color: white;
  position: absolute;
  top: 5%;
  z-index: 3;
}
.create-profile-container .second-row .overlay-container .top {
  display: flex;
  padding: 10px 50px;
  padding-right: 50%;
  align-items: center;
  column-gap: 20px;
}
.create-profile-container .second-row .overlay-container .top button {
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 22px;
}
.create-profile-container .second-row .overlay-container .wrapper {
  padding: 10px 50px;
  padding-right: 50%;
}
.create-profile-container .second-row .overlay-container .wrapper .header-3 {
  padding: 20px 0;
}
.create-profile-container .second-row .text-container {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.create-profile-container .second-row .text-container .text-item input {
  display: none;
}
.create-profile-container .second-row .text-container .text-item span {
  position: relative;
  z-index: 3;
  background-color: #FFCAD4;
  box-sizing: border-box;
  padding: 4px 15px;
  border-radius: 50px;
  font-weight: 500;
}
.create-profile-container .second-row .text-container .text-item span.activated {
  background-color: #FF8FAB;
}
.create-profile-container .second-row .text-container .text-item span.not-activated {
  background-color: #FFCAD4;
}

.sign-up-employer {
  background-color: #fff;
  height: 100%;
  display: flex;
}
@media (max-width: 780px) {
  .sign-up-employer {
    flex-direction: column;
  }
}
.sign-up-employer .left {
  background-color: #F4ACB7;
  width: 50%;
  border-radius: 0 25px 25px 0;
}
@media (max-width: 780px) {
  .sign-up-employer .left {
    width: 100%;
    border-radius: 0;
  }
}
.sign-up-employer .left .text-container {
  width: max-content;
  margin: auto;
  padding-top: 150px;
}
@media (max-width: 780px) {
  .sign-up-employer .left .text-container {
    padding: 30px 0;
  }
}
.sign-up-employer .left .decor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}
@media (max-width: 780px) {
  .sign-up-employer .left .decor-container {
    display: none;
  }
}
.sign-up-employer .left .decor-container .one {
  border: 3px solid #C9184A;
}
.sign-up-employer .left .decor-container .two {
  border: 3px solid #C9184A;
}
.sign-up-employer .left .decor-container .line {
  border-right: 3px solid #C9184A;
  width: 1px;
  height: 50px;
}
.sign-up-employer .left .decor-container .line-two {
  border-right: 3px solid #C9184A;
  width: 1px;
  height: 50px;
}
.sign-up-employer .left .decor-container .three {
  border: 3px solid #FF5D8F;
}
.sign-up-employer .left .decor-container .circle {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
}
.sign-up-employer .left .flex-header {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #fff;
}
.sign-up-employer .left .flex-subtitle {
  font-weight: 700;
  font-size: 30px;
  color: white;
}
.sign-up-employer .right {
  width: 50%;
  min-height: 100vh;
}
@media (max-width: 780px) {
  .sign-up-employer .right {
    width: 100%;
  }
}
.sign-up-employer .right .img-container {
  border: 1px dotted;
  display: flex;
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  box-sizing: border-box;
  padding: 45px 0;
  width: 200px;
  margin: auto;
  border-radius: 15px;
}
.sign-up-employer .right img {
  width: 120px;
}
.sign-up-employer .right .sub-text {
  padding: 10px 0;
  font-weight: 700;
  color: #939090;
}
.sign-up-employer .right .sub-text-2 {
  padding: 10px 0;
  font-weight: 700;
  color: #939090;
  width: 200px;
  margin: auto;
}
.sign-up-employer .right .header {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #F4ACB7;
  text-align: center;
  padding-top: 50px;
}
.sign-up-employer .right .container {
  background-color: #F0EBD8;
  box-sizing: border-box;
  margin: 20px 40px;
  padding: 20px 20px;
  border-radius: 15px;
}
.sign-up-employer .right .title {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
}
.sign-up-employer .right .text {
  font-weight: 700;
}

.form-container {
  background-color: #fff;
  height: 80vh;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px;
}
.form-container .title {
  font-size: 28px;
  font-weight: 700;
}
.form-container .field-container {
  display: flex;
  flex-direction: column;
}
.form-container .field-container .first-row,
.form-container .field-container .second-row,
.form-container .field-container .form-row {
  padding-top: 15px;
  column-gap: 100px;
}
.form-container .field-container .first-row .form-item,
.form-container .field-container .second-row .form-item,
.form-container .field-container .form-row .form-item {
  display: flex;
  flex-direction: column;
  width: 33%;
  row-gap: 10px;
}
.form-container .field-container .first-row .form-item select,
.form-container .field-container .first-row .form-item input,
.form-container .field-container .first-row .form-item textarea,
.form-container .field-container .second-row .form-item select,
.form-container .field-container .second-row .form-item input,
.form-container .field-container .second-row .form-item textarea,
.form-container .field-container .form-row .form-item select,
.form-container .field-container .form-row .form-item input,
.form-container .field-container .form-row .form-item textarea {
  background-color: #FFCAD4;
  border: 1px solid black;
  border-radius: 7px;
  padding: 3px 10px;
}
.form-container .field-container .first-row .form-item div[contenteditable],
.form-container .field-container .second-row .form-item div[contenteditable],
.form-container .field-container .form-row .form-item div[contenteditable] {
  background-color: #FFCAD4;
  border: 1px solid black;
  border-radius: 7px;
  padding: 3px 10px;
  max-height: 200px;
  overflow: auto;
}
.form-container .field-container .last-row {
  justify-content: flex-end;
}
.form-container .field-container .last-row .form-item {
  width: calc(33% - 62px);
}
.form-container .field-container .last-row button {
  padding: 10px 0px;
  width: 100%;
  background-color: #F4ACB7;
  border: 1px solid black;
  border-radius: 8px;
}

.profile-user-container .back-home {
  box-sizing: border-box;
  width: 90%;
  margin: auto;
  padding: 15px 0px;
}
.profile-user-container .back-home button {
  background: none;
  border: 0px;
  font-size: 20px;
  font-weight: 700;
}
.profile-user-container .back-home button:hover {
  color: #fff;
}
.profile-user-container .profile-container {
  width: 90%;
  margin: auto;
  column-gap: 30px;
}
@media (max-width: 1280px) {
  .profile-user-container .profile-container {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
}
.profile-user-container .profile-container .left {
  background-color: white;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px;
  max-width: 300px;
  width: 23%;
  height: max-content;
}
@media (max-width: 1280px) {
  .profile-user-container .profile-container .left {
    max-width: none;
    width: 100%;
  }
}
.profile-user-container .profile-container .left .image {
  display: flex;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.profile-user-container .profile-container .left .star-rating {
  display: flex;
  justify-content: center;
  font-size: 25px;
  flex-direction: column;
}
.profile-user-container .profile-container .left .star-rating .star.filled {
  color: #FF8FAB;
  /* Filled star color */
}
.profile-user-container .profile-container .left .star-rating div {
  display: flex;
  justify-content: center;
}
.profile-user-container .profile-container .left .reviews {
  font-size: 18px;
}
.profile-user-container .profile-container .left .header {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
.profile-user-container .profile-container .left .sub-header {
  font-weight: 700;
  font-size: 24px;
  text-decoration: underline;
  padding-top: 20px;
}
.profile-user-container .profile-container .left .grid-container {
  padding-bottom: 10px;
}
.profile-user-container .profile-container .left .grey-body {
  font-size: 20px;
  padding-top: 10px;
  font-weight: 600;
}
.profile-user-container .profile-container .left .body-text {
  font-size: 20px;
  word-wrap: break-word;
}
@media (max-width: 1280px) {
  .profile-user-container .profile-container .left .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .left .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .left .grid-container {
    grid-template-columns: 1fr;
  }
}
.profile-user-container .profile-container .left .edit-button-container {
  display: flex;
  justify-content: center;
}
.profile-user-container .profile-container .left .edit-button-container button {
  background-color: #FFCAD4;
  padding: 10px 30px;
  border: 0;
  width: 100%;
  border-radius: 100px;
  font-size: 18px;
  transition: background-color 0.2s ease-in;
}
.profile-user-container .profile-container .left .edit-button-container button:hover {
  background-color: #FF8FAB;
  color: white;
}
.profile-user-container .profile-container .left .descriptor-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  font-weight: 500;
  column-gap: 10px;
  padding: 10px 0 30px 0;
}
.profile-user-container .profile-container .left .descriptor-container .descriptor-item {
  box-sizing: border-box;
  width: max-content;
  background-color: #FF8FAB;
  padding: 4px 15px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: 400;
}
.profile-user-container .profile-container .left .edit-button {
  width: 90%;
  margin: auto;
  font-weight: 700;
  font-size: 20px;
  border: 0px;
  background-color: #FFCAD4;
  border-radius: 50px;
  padding: 5px 0;
  display: block;
}
.profile-user-container .profile-container .left .edit-button:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.profile-user-container .profile-container .right {
  width: 80%;
}
@media (max-width: 1280px) {
  .profile-user-container .profile-container .right {
    width: 100%;
  }
}
.profile-user-container .profile-container .right .upcoming-container {
  background-color: #fff;
  flex-direction: column;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0 40px 0;
  margin-bottom: 30px;
}
.profile-user-container .profile-container .right .upcoming-container .body-text {
  margin-top: 5px;
  font-size: 20px;
}
.profile-user-container .profile-container .right .upcoming-container .header {
  font-weight: 700;
  font-size: 24px;
}
.profile-user-container .profile-container .right .listing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 10px;
}
.profile-user-container .profile-container .right .listing-container .listing-item {
  background-color: #FFCAD4;
  margin: auto;
  width: 80%;
  display: flex;
  height: auto;
  padding: 10px 30px;
  border-radius: 15px;
  box-sizing: border-box;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item {
    flex-direction: column;
    padding: 20px 30px;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .line-decor {
  width: 1px;
  height: 100%;
  border-left: 1px solid black;
}
.profile-user-container .profile-container .right .listing-container .listing-item .sub-header {
  font-weight: 700;
  font-size: 18px;
}
.profile-user-container .profile-container .right .listing-container .listing-item .left-side {
  column-gap: 15px;
  width: 40%;
  align-items: center;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .left-side {
    width: 100%;
    padding-right: 7px;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .left-side {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .left-side .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  height: 55px;
  width: 55px;
  flex-grow: 0;
  flex-shrink: 0;
}
.profile-user-container .profile-container .right .listing-container .listing-item .left-side img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.profile-user-container .profile-container .right .listing-container .listing-item .middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 25%;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .middle {
    width: 50%;
    padding-left: 70px;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .middle {
    width: 80%;
    padding-left: 0;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .middle::before {
  content: "";
  position: absolute;
  left: -20px;
  width: 1px;
  height: 100%;
  border-left: 2px solid black;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .middle::before {
    left: -5%;
    display: none;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .middle::before {
    display: none;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .right-side {
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  position: relative;
  width: 25%;
  max-width: 188px;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .right-side {
    width: 50%;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .right-side {
    max-width: none;
    flex-direction: column;
    column-gap: 5px;
    width: 100%;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .right-side::before {
  content: "";
  position: absolute;
  left: -30px;
  width: 1px;
  height: 100%;
  border-left: 2px solid black;
}
@media (max-width: 1024px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .right-side::before {
    left: -10%;
    display: none;
  }
}
@media (max-width: 780px) {
  .profile-user-container .profile-container .right .listing-container .listing-item .right-side::before {
    display: none;
  }
}
.profile-user-container .profile-container .right .listing-container .listing-item .right-side button {
  border: 0px;
  width: 100%;
  background-color: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 30px;
  text-align: center;
  border-radius: 100px;
}
.profile-user-container .profile-container .right .listing-container .listing-item .right-side button:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.profile-user-container .job-listing-container {
  display: flex;
  background: #FFCAD4;
  border-radius: 10px;
  padding: 20px 10px;
  width: 80%;
  margin: auto;
  justify-content: space-around;
  box-sizing: border-box;
}
@media (max-width: 780px) {
  .profile-user-container .job-listing-container {
    flex-direction: column;
  }
}
.profile-user-container .job-listing-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.profile-user-container .job-listing-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-user-container .job-listing-container .job-title-container {
  font-size: 22px;
  width: 50%;
  padding-left: 20px;
  align-self: center;
  text-align: center;
}
@media (max-width: 780px) {
  .profile-user-container .job-listing-container .job-title-container {
    padding-left: 0;
  }
}
.profile-user-container .job-listing-container .date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 50%;
}
@media (max-width: 780px) {
  .profile-user-container .job-listing-container .date-container {
    width: 100%;
  }
}
.profile-user-container .job-listing-container .date-container .title {
  font-size: 20px;
}
.profile-user-container .job-listing-container .date-container .date {
  font-size: 20px;
}

.language-component {
  position: absolute;
  right: 10px;
  top: 3px;
  display: flex;
  color: black;
}
.language-component div {
  padding: 5px 20px;
  display: flex;
  align-items: center;
}
.language-component .active {
  background-color: rgba(245, 40, 145, 0.4);
  border-radius: 10px;
}

.Translation-i18-Component {
  display: absolute;
}

.view-candidate-employer .view-candidates-component {
  padding-top: 30px;
  border-radius: 10px;
  width: 90%;
  margin: auto;
}
.view-candidate-employer .view-candidates-component .background-container {
  background-color: #fff;
  padding: 30px 0;
}
.view-candidate-employer .view-candidates-component .background-container .title {
  margin-left: 9%;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 10px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container {
  padding-bottom: 10px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .left {
  display: flex;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container .details-container {
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 100px;
  font-weight: 700;
  font-size: 18px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container .details-container:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container .icon-container {
  border-radius: 50%;
  background-color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: red;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container .icon-container.tick {
  color: green;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .button-container .icon-container:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .component {
  background-color: #FFCAD4;
  width: 80%;
  display: flex;
  padding: 10px 20px;
  margin: auto;
  border-radius: 13px;
  justify-content: space-between;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .component .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .component .img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .component .text-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.view-candidate-employer .view-candidates-component .background-container .component-container .component .text-container .name {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 24px;
  font-weight: 600;
}

.view-candidate .grid-container {
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 30px;
  padding-top: 30px;
  row-gap: 30px;
  grid-template-rows: auto;
}
.view-candidate .grid-container .selected-jobs-component {
  grid-column: 1/2;
}
@media (max-width: 800px) {
  .view-candidate .grid-container .selected-jobs-component {
    grid-column: 1/3;
  }
}
.view-candidate .grid-container .selected-job-container {
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 10px 20px 10px;
  border-radius: 10px;
  height: max-content;
}
.view-candidate .grid-container .selected-job-container .title {
  font-size: 30px;
  font-weight: 600;
  width: 95%;
  margin: auto;
}
.view-candidate .grid-container .selected-job-container .job-listing-container {
  display: flex;
  column-gap: 20px;
  border: 3px solid #F4ACB7;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .view-candidate .grid-container .selected-job-container .job-listing-container {
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .view-candidate .grid-container .selected-job-container .job-listing-container {
    flex-direction: row;
  }
}
.view-candidate .grid-container .selected-job-container .job-listing-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.view-candidate .grid-container .selected-job-container .job-listing-container .text-container .job-title {
  font-size: 24px;
  font-weight: 500;
}
.view-candidate .grid-container .selected-job-container .job-listing-container .text-container .information {
  font-size: 20px;
}
.view-candidate .grid-container .selected-job-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
  flex: 0 0 50px;
}
.view-candidate .grid-container .selected-job-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.view-candidate .grid-container .confirmed-workers-container, .view-candidate .grid-container .candidates-container {
  background-color: #fff;
  border-radius: 10px;
}
.view-candidate .grid-container .confirmed-workers-container .title, .view-candidate .grid-container .candidates-container .title {
  font-size: 30px;
  font-weight: 600;
  width: 95%;
  padding: 15px 0;
  margin: auto;
}
.view-candidate .grid-container .confirmed-workers-container .worker-container, .view-candidate .grid-container .candidates-container .worker-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 25px;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item, .view-candidate .grid-container .candidates-container .worker-listing-item {
  display: flex;
  background-color: #F4ACB7;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
  padding: 10px 20px;
  align-items: center;
  border-radius: 10px;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .img-container, .view-candidate .grid-container .candidates-container .worker-listing-item .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .img-container img, .view-candidate .grid-container .candidates-container .worker-listing-item .img-container img {
  width: 100%;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .name-container, .view-candidate .grid-container .candidates-container .worker-listing-item .name-container {
  font-size: 20px;
  font-weight: 600;
  padding-left: 15px;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .candidate-anchor, .view-candidate .grid-container .candidates-container .worker-listing-item .candidate-anchor {
  margin-left: auto;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .view-details-btn, .view-candidate .grid-container .candidates-container .worker-listing-item .view-details-btn {
  margin-left: auto;
  border: 0;
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 100px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.view-candidate .grid-container .confirmed-workers-container .worker-listing-item .view-details-btn:hover, .view-candidate .grid-container .candidates-container .worker-listing-item .view-details-btn:hover {
  background-color: #FF5D8F;
  color: white;
}
@media (max-width: 800px) {
  .view-candidate .grid-container .confirmed-workers-container {
    grid-column: 1/3;
  }
}
.view-candidate .grid-container .candidates-container {
  grid-column: 2/3;
  background-color: #fff;
}
@media (max-width: 800px) {
  .view-candidate .grid-container .candidates-container {
    grid-column: 1/3;
  }
}

.view-candidate-details .grid-container {
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 30px;
  padding-top: 30px;
  row-gap: 30px;
}
@media (max-width: 1000px) {
  .view-candidate-details .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
.view-candidate-details .grid-container .selected-job-container {
  background-color: #fff;
  grid-column: 1/2;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 10px 20px 10px;
  border-radius: 10px;
  height: max-content;
}
.view-candidate-details .grid-container .selected-job-container .title {
  font-size: 30px;
  font-weight: 600;
  width: 90%;
}
.view-candidate-details .grid-container .selected-job-container .job-listing-container {
  display: flex;
  column-gap: 20px;
  border: 3px solid #F4ACB7;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.view-candidate-details .grid-container .selected-job-container .job-listing-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.view-candidate-details .grid-container .selected-job-container .job-listing-container .text-container .job-title {
  font-size: 24px;
  font-weight: 500;
}
.view-candidate-details .grid-container .selected-job-container .job-listing-container .text-container .information {
  font-size: 20px;
}
.view-candidate-details .grid-container .selected-job-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
  flex: 0 0 50px;
}
.view-candidate-details .grid-container .selected-job-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.view-candidate-details .grid-container {
  grid-template-rows: auto 1fr;
}
@media (max-width: 700px) {
  .view-candidate-details .selected-jobs-component {
    grid-column: 1/3;
  }
}
.view-candidate-details .profile-container {
  grid-row: span 2;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  height: max-content;
}
@media (max-width: 1000px) {
  .view-candidate-details .profile-container {
    grid-column: 1/3;
    grid-row: auto;
  }
}
.view-candidate-details .profile-container .top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}
.view-candidate-details .profile-container .top-container .img-container {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
}
.view-candidate-details .profile-container .top-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.view-candidate-details .profile-container .top-container .name {
  font-size: 40px;
  font-weight: 600;
}
.view-candidate-details .profile-container .mid-container {
  display: flex;
  margin-top: 60px;
  column-gap: 30px;
}
@media (max-width: 800px) {
  .view-candidate-details .profile-container .mid-container {
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
  }
}
.view-candidate-details .profile-container .mid-container .left {
  width: 50%;
}
@media (max-width: 800px) {
  .view-candidate-details .profile-container .mid-container .left {
    width: 100%;
  }
}
.view-candidate-details .profile-container .mid-container .left .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 15px;
}
.view-candidate-details .profile-container .mid-container .left .best-five-container {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}
.view-candidate-details .profile-container .mid-container .left .best-five-container .best-five-item {
  background-color: #F4ACB7;
  padding: 5px 25px;
  font-size: 22px;
  border-radius: 100px;
}
.view-candidate-details .profile-container .mid-container .right {
  width: 50%;
}
@media (max-width: 800px) {
  .view-candidate-details .profile-container .mid-container .right {
    width: 100%;
  }
}
.view-candidate-details .profile-container .mid-container .right .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 15px;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item {
  display: flex;
  background-color: #F4ACB7;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 65%;
  border-right: 3px solid black;
  left: 50%;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item .job-title, .view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item .date-container {
  width: 50%;
  padding-left: 20px;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item .job-title {
  font-size: 24px;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item .date-container {
  font-size: 20px;
}
.view-candidate-details .profile-container .mid-container .right .past-jobs-container .past-job-item .date-container .sub-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
}
.view-candidate-details .profile-container .bottom-container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 80px;
}
.view-candidate-details .profile-container .bottom-container button {
  border: 0;
  padding: 10px 50px;
  border-radius: 100px;
  font-size: 20px;
}
.view-candidate-details .profile-container .bottom-container .reject {
  background-color: #F4ACB7;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.view-candidate-details .profile-container .bottom-container .reject:hover {
  background-color: #FF5D8F;
  color: #fff;
}
.view-candidate-details .profile-container .bottom-container .accept {
  background-color: #8DFFAD;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.view-candidate-details .profile-container .bottom-container .accept:hover {
  background-color: #14c400;
  color: #fff;
}
.view-candidate-details .candidates-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (max-width: 1000px) {
  .view-candidate-details .candidates-container {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (max-width: 700px) {
  .view-candidate-details .candidates-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}
.view-candidate-details .candidates-container .title {
  font-size: 30px;
  font-weight: 600;
}
.view-candidate-details .candidates-container .candidate-item {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  align-items: center;
  column-gap: 15px;
  font-size: 24px;
  box-sizing: border-box;
}
.view-candidate-details .candidates-container .candidate-item:hover {
  box-shadow: 0 0 10px 3px #54c7f8;
}
.view-candidate-details .candidates-container .candidate-item .img-container {
  display: flex;
  width: 60px;
  height: 60px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.view-candidate-details .candidates-container .candidate-item .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media print {
  body {
    margin: 0 !important;
  }
}
.main-container {
  font-family: "Lato";
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}

.dropdown {
  position: relative;
  display: flex;
  width: 100%;
  height: 41px;
  align-items: center;
}
.dropdown::before {
  content: ">";
  position: absolute;
  right: 15px;
  transform: translateY(-50%) rotate(90deg) scaleY(1.5) scaleX(0.7);
  font-size: 24px;
  top: 50%;
  pointer-events: none;
}
.dropdown .dropdown-title {
  width: 100%;
  background-color: #fff;
  padding: 5px !important;
  font-size: 20px;
  border: 1px solid black;
}

.dropdown-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 39px;
  background-color: #FF8FAB;
  border: 1px solid #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 99%;
  z-index: 1;
}

.dropdown-item {
  padding: 10px !important;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #F4ACB7;
}

.submenu-item {
  padding: 10px 20px !important;
  background-color: #fff;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: #F4ACB7;
}

.submenu-item.selected {
  background-color: #F4ACB7;
  /* Light green background */
}

.dropdown-buttons-container button {
  border: 0px;
  box-sizing: border-box;
  padding: 7px 30px;
  border-radius: 24px;
  margin-left: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.dropdown-buttons-container button:hover {
  background-color: #F4ACB7;
  color: #fff;
}

.EmployerReview .grid-container {
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 30px;
  padding-top: 30px;
  row-gap: 30px;
}
@media (max-width: 1024px) {
  .EmployerReview .grid-container {
    grid-template-columns: 1fr;
  }
}

.selected-job-container {
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px 10px 20px 10px;
  border-radius: 10px;
  height: max-content;
}
.selected-job-container .title {
  font-size: 30px;
  font-weight: 600;
  width: 90%;
}
.selected-job-container .job-listing-container {
  display: flex;
  column-gap: 20px;
  border: 3px solid #F4ACB7;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.selected-job-container .job-listing-container .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
  flex: 0 0 50px;
}
.selected-job-container .job-listing-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.selected-job-container .job-listing-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.selected-job-container .job-listing-container .text-container .job-title {
  font-size: 24px;
  font-weight: 500;
}
.selected-job-container .job-listing-container .text-container .information {
  font-size: 20px;
}

.ReviewComponent {
  grid-row: span 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  height: max-content;
}

.EmployerReview .top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}
.EmployerReview .top-container .img-container {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
}
.EmployerReview .top-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.EmployerReview .top-container .name {
  font-size: 40px;
  font-weight: 600;
}
.EmployerReview .candidates-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.EmployerReview .candidates-container .title {
  font-size: 30px;
  font-weight: 600;
}
.EmployerReview .candidates-container .candidate-item {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  align-items: center;
  column-gap: 15px;
  font-size: 24px;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
}
.EmployerReview .candidates-container .candidate-item:hover {
  box-shadow: 0 0 10px 3px #54c7f8;
}
.EmployerReview .candidates-container .candidate-item .img-container {
  display: flex;
  width: 60px;
  height: 60px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.EmployerReview .candidates-container .candidate-item .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ReviewComponent .sub-title {
  font-size: 24px;
  font-weight: 600;
}
.ReviewComponent .compliment-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin: 10px 0;
}
.ReviewComponent .compliment-wrapper .compliment {
  background-color: #F4ACB7;
  padding: 5px 25px;
  font-size: 22px;
  border-radius: 100px;
  transition: background-color 0.2s ease, color 0.2s ease;
}
.ReviewComponent .compliment-wrapper .compliment:hover {
  background-color: #FF8FAB;
  color: white;
}
.ReviewComponent .compliment-wrapper .compliment.selected {
  background-color: #FF8FAB;
  color: white;
}
.ReviewComponent textarea {
  border: 0px;
  background-color: #ccc;
  width: 100%;
  min-height: 19vh;
  border-radius: 15px;
  vertical-align: top;
  padding: 10px 15px;
  box-sizing: border-box;
  margin: 10px 0;
}
.ReviewComponent .bottom-container {
  display: flex;
}
.ReviewComponent .bottom-container .star-rating-container {
  width: 50%;
}
.ReviewComponent .bottom-container .submit-review-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ReviewComponent .bottom-container .confirm-button {
  border: 0;
  background-color: #8DFFAD;
  border-radius: 100px;
  font-size: 19px;
  font-weight: 600;
  padding: 5px 30px;
  transition: background-color 0.3s ease;
  width: max-content;
}
.ReviewComponent .bottom-container .confirm-button:hover {
  background-color: #228B22;
  color: #fff;
}
.ReviewComponent .star-rating {
  display: flex;
  cursor: pointer;
}
.ReviewComponent .star {
  font-size: 2rem;
  /* Adjust size as needed */
  color: #ccc;
  /* Default star color */
  transition: color 0.2s;
}
.ReviewComponent .star.filled {
  color: #FF8FAB;
  /* Color for filled stars */
}

.UserProfileCard {
  min-width: 300px;
}
.UserProfileCard .left {
  background-color: white;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px;
  max-width: 300px;
  width: 100%;
  height: max-content;
}
@media (max-width: 1280px) {
  .UserProfileCard .left {
    max-width: none;
    width: 100%;
  }
}
.UserProfileCard .left .image {
  display: flex;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.UserProfileCard .left .star-rating {
  display: flex;
  justify-content: center;
  font-size: 25px;
  flex-direction: column;
}
.UserProfileCard .left .star-rating .star.filled {
  color: #FF8FAB;
  /* Filled star color */
}
.UserProfileCard .left .star-rating .reviews {
  font-size: 18px;
}
.UserProfileCard .left .star-rating div {
  display: flex;
  justify-content: center;
}
.UserProfileCard .left .header {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
.UserProfileCard .left .sub-header {
  font-weight: 700;
  font-size: 24px;
  text-decoration: underline;
  padding-top: 20px;
}
.UserProfileCard .left .grid-container {
  padding-bottom: 10px;
}
.UserProfileCard .left .grey-body {
  font-size: 20px;
  padding-top: 10px;
  font-weight: 600;
}
.UserProfileCard .left .body-text {
  font-size: 20px;
  word-wrap: break-word;
}
@media (max-width: 1280px) {
  .UserProfileCard .left .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 1024px) {
  .UserProfileCard .left .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 780px) {
  .UserProfileCard .left .grid-container {
    grid-template-columns: 1fr;
  }
}
.UserProfileCard .left .edit-button-container {
  display: flex;
  justify-content: center;
}
.UserProfileCard .left .edit-button-container button {
  background-color: #FFCAD4;
  padding: 10px 30px;
  border: 0;
  width: 100%;
  border-radius: 100px;
  font-size: 18px;
  transition: background-color 0.2s ease-in;
}
.UserProfileCard .left .edit-button-container button:hover {
  background-color: #FF8FAB;
  color: white;
}
.UserProfileCard .left .descriptor-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  font-weight: 500;
  column-gap: 10px;
  padding: 10px 0 30px 0;
}
.UserProfileCard .left .descriptor-container .descriptor-item {
  box-sizing: border-box;
  width: max-content;
  background-color: #FF8FAB;
  padding: 4px 15px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: 400;
}
.UserProfileCard .left .edit-button {
  width: 90%;
  margin: auto;
  font-weight: 700;
  font-size: 20px;
  border: 0px;
  background-color: #FFCAD4;
  border-radius: 50px;
  padding: 5px 0;
  display: block;
}
.UserProfileCard .left .edit-button:hover {
  background-color: #FF5D8F;
  color: #fff;
}

.profile-container {
  width: 90%;
  margin: auto;
  padding-top: 30px;
  display: flex;
  column-gap: 30px;
}
@media (max-width: 1024px) {
  .profile-container {
    flex-direction: column;
    row-gap: 30px;
  }
}
.profile-container .EmployeeReviewComponent {
  background-color: #fff;
  flex-grow: 1;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px 0 40px 0;
}
.profile-container .EmployeeReviewComponent .review-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 20px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard {
  width: 90%;
  background-color: #FFCAD4;
  border-radius: 15px;
  padding: 25px;
  box-sizing: border-box;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container {
  display: flex;
  column-gap: 10px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .img-container {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .title {
  padding: 0;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .bottom-text-container {
  display: flex;
  column-gap: 10px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .bottom-text-container .date {
  font-size: 18px;
  margin-top: 5px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .bottom-text-container .StarsDisplay {
  font-size: 20px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .bottom-text-container .star {
  color: #FFF;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .top-container .text-container .bottom-text-container .star.filled {
  color: #000;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .compliments-container {
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .compliments-container .compliment {
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 100px;
}
.profile-container .EmployeeReviewComponent .review-card-container .EmployeeReviewCard .review-container {
  margin-top: 10px;
}
.profile-container .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding: 0px 0 10px 0;
}
.profile-container .sub-title {
  font-size: 20px;
  text-align: center;
}

.WorkshopCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}
.WorkshopCard:hover {
  background-color: #FFCAD4;
}
.WorkshopCard .img-container {
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WorkshopCard .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.WorkshopCard .text-container {
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.WorkshopCard .text-container .title {
  font-size: 24px;
  font-weight: 600;
}
.WorkshopCard .text-container .details {
  font-size: 20px;
}
.WorkshopCard .text-container .hosted-container {
  font-size: 18px;
}

.workshop-details-container .details-container .left {
  width: 100%;
}
.workshop-details-container .section-container-1 {
  display: flex;
  margin-top: 30px;
  column-gap: 20px;
}
.workshop-details-container .section-container-1 .img-container {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
}
.workshop-details-container .section-container-1 .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.workshop-details-container .section-container-1 .text-container .title {
  font-size: 28px;
  font-weight: 600;
}
.workshop-details-container .section-container-1 .text-container .sub-title {
  font-size: 20px;
}
.workshop-details-container .section-container-2 {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}
.workshop-details-container .section-container-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.workshop-details-container .section-container-3 {
  font-size: 20px;
  margin-top: 20px;
}
.workshop-details-container .section-container-4 {
  margin-top: 20px;
}
.workshop-details-container .section-container-4 .title {
  font-size: 28px;
  font-weight: 600;
}
.workshop-details-container .section-container-4 .highlight-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 15px;
  font-size: 18px;
}
.workshop-details-container .section-container-5 {
  margin-top: 20px;
  padding-bottom: 50px;
}
.workshop-details-container .section-container-5 .title {
  font-size: 28px;
  font-weight: 600;
}
.workshop-details-container .section-container-5 .instructor-card {
  display: flex;
  column-gap: 15px;
  margin-top: 15px;
}
.workshop-details-container .section-container-5 .instructor-card .img-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.workshop-details-container .section-container-5 .instructor-card .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.workshop-details-container .section-container-5 .instructor-card .text-container .name {
  font-size: 22px;
  font-weight: 600;
}
.workshop-details-container .section-container-5 .instructor-card .text-container .description {
  font-size: 18px;
}

.workshop-details-container .details-container .job-summary .title {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 600;
}

.SignUpEmployeeNew {
  background-color: #fff;
  max-width: 500px;
  margin: auto;
  margin-top: 40px;
  overflow: hidden;
  border-radius: 20px;
  width: 95%;
}
.SignUpEmployeeNew .step-indicator {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Adjust as needed */
}
.SignUpEmployeeNew .step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-weight: bold;
}
.SignUpEmployeeNew .step-circle.active {
  background-color: #F4ACB7;
  /* Active step color */
  color: white;
}
.SignUpEmployeeNew .step-component {
  box-sizing: border-box;
  padding: 30px 20px;
  position: relative;
  min-height: 90vh;
}
.SignUpEmployeeNew .step-component .button {
  padding: 5px 15px;
  border: none;
  background-color: #FFCAD4;
  border-radius: 100px;
}
.SignUpEmployeeNew .step-component.blur .dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  pointer-events: none;
}
.SignUpEmployeeNew .step-component .past-job-entry {
  display: flex;
  background-color: #FFE2E9;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 20px 20px 20px;
  border-radius: 15px;
  row-gap: 5px;
}
.SignUpEmployeeNew .step-component .past-job-entry .one {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.SignUpEmployeeNew .step-component .past-job-entry .one .employment-type {
  background-color: #FFCAD4;
  padding: 5px 10px;
  border-radius: 10px;
}
.SignUpEmployeeNew .step-component .past-job-entry span {
  font-size: 18px;
  font-weight: 600;
}
.SignUpEmployeeNew .step-component .employment-type-options {
  display: flex;
  justify-content: space-around;
}
.SignUpEmployeeNew .step-component .employment-type-options .employment-type {
  background-color: #FFCAD4;
  padding: 5px 10px;
  border-radius: 7px;
}
.SignUpEmployeeNew .step-component .employment-type-options .employment-type.selected {
  background-color: #F4ACB7;
  color: white;
}
.SignUpEmployeeNew .step-component .past-job-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 15px;
  max-width: 300px;
  width: 90%;
  z-index: 3;
  box-sizing: border-box;
  padding: 20px;
  height: 80vh;
}
.SignUpEmployeeNew .step-component .past-job-overlay .overlay-content h3 {
  margin: 0;
}
.SignUpEmployeeNew .step-component .past-job-overlay .overlay-content div {
  margin-top: 15px;
}
.SignUpEmployeeNew .step-component .past-job-overlay .overlay-content div .employment-type {
  margin-top: 0;
}
.SignUpEmployeeNew .step-component .past-job-overlay .overlay-content div textarea {
  width: 100%;
}
.SignUpEmployeeNew .step-component .past-job-overlay .button-container button {
  padding: 10px 30px;
}
.SignUpEmployeeNew .step-component .past-job-overlay .button-container .job-button {
  width: 50%;
  padding: 10px 0;
}
.SignUpEmployeeNew .step-component .past-job-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.SignUpEmployeeNew .step-component .add-job-button {
  width: 100%;
  background-color: #FFCAD4;
  border: none;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.SignUpEmployeeNew .step-component .option-item,
.SignUpEmployeeNew .step-component .language-option {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  /* Style the label to look like a checkbox */
  /* Change the background and border color when checked */
  /* Optional: Add a checkmark */
  /* Scale the checkmark when checked */
}
.SignUpEmployeeNew .step-component .option-item label,
.SignUpEmployeeNew .step-component .language-option label {
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
}
.SignUpEmployeeNew .step-component .option-item input,
.SignUpEmployeeNew .step-component .language-option input {
  width: auto;
}
.SignUpEmployeeNew .step-component .option-item input[type=checkbox],
.SignUpEmployeeNew .step-component .language-option input[type=checkbox] {
  display: none;
}
.SignUpEmployeeNew .step-component .option-item .custom-checkbox,
.SignUpEmployeeNew .step-component .language-option .custom-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* Make it circular */
  border: 2px solid #ccc;
  /* Border color */
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
.SignUpEmployeeNew .step-component .option-item input[type=checkbox]:checked + .custom-checkbox,
.SignUpEmployeeNew .step-component .language-option input[type=checkbox]:checked + .custom-checkbox {
  background-color: pink;
  /* Background color when checked */
  border-color: pink;
  /* Border color when checked */
}
.SignUpEmployeeNew .step-component .option-item .custom-checkbox::after,
.SignUpEmployeeNew .step-component .language-option .custom-checkbox::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  /* Checkmark color */
  border-radius: 50%;
  /* Make checkmark circular */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  /* Initially hidden */
  transition: transform 0.3s;
}
.SignUpEmployeeNew .step-component .option-item input[type=checkbox]:checked + .custom-checkbox::after,
.SignUpEmployeeNew .step-component .language-option input[type=checkbox]:checked + .custom-checkbox::after {
  transform: translate(-50%, -50%) scale(1);
  /* Show checkmark */
}
.SignUpEmployeeNew .step-component .option-overlay {
  background-color: #FFF;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.SignUpEmployeeNew .step-component .option-overlay .overlay-content {
  padding: 30px;
  box-sizing: border-box;
}
.SignUpEmployeeNew .step-component .option-overlay .overlay-content h3 {
  margin: 0;
  padding-bottom: 10px;
}
.SignUpEmployeeNew .step-component .blur {
  filter: blur(2px);
  transition: filter 0.3s ease;
}
.SignUpEmployeeNew .step-component .title {
  font-size: 30px;
  font-weight: 600;
}
.SignUpEmployeeNew .step-component .sub-title {
  font-size: 20px;
  font-weight: 600;
}
.SignUpEmployeeNew .step-component .item-1,
.SignUpEmployeeNew .step-component .item-2 {
  margin-top: 30px;
}
.SignUpEmployeeNew .step-component .item-1 .tags-display,
.SignUpEmployeeNew .step-component .item-2 .tags-display {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin: 10px 0 10px 0;
}
.SignUpEmployeeNew .step-component .item-1 .tags-display .tag,
.SignUpEmployeeNew .step-component .item-2 .tags-display .tag {
  background-color: #FFCAD4;
  padding: 5px 15px;
  border-radius: 100px;
}
.SignUpEmployeeNew .step-component .item-1 button,
.SignUpEmployeeNew .step-component .item-2 button {
  background-color: #FFCAD4;
  border: none;
  padding: 5px 30px;
  border-radius: 100px;
}
.SignUpEmployeeNew .step-component .input-field-item {
  margin-top: 40px;
}
.SignUpEmployeeNew .step-component .bottom-container {
  bottom: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.SignUpEmployeeNew .step-component input {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid #D9D9D9;
}
.SignUpEmployeeNew .step-component .language-toggle {
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid #D9D9D9;
}
.SignUpEmployeeNew .step-component .language-option {
  display: flex;
  margin-top: 15px;
}
.SignUpEmployeeNew .step-component .language-option label {
  width: 100%;
}
.SignUpEmployeeNew .step-component .language-option input {
  width: auto;
}
.SignUpEmployeeNew .step-component .language-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  box-sizing: border-box;
  height: 80%;
  border-radius: 8px;
  z-index: 2;
}
.SignUpEmployeeNew .step-component .button-container {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin-top: 30px;
}
.SignUpEmployeeNew .step-component .button-container button {
  background-color: #FFCAD4;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 5px 35px;
  transition: background-color 0.2s ease;
  width: max-content;
}
.SignUpEmployeeNew .step-component .button-container button:hover {
  background-color: #F4ACB7;
}

.App {
  padding-bottom: 15px;
}

