@use "./Breakpoints" as *;
html {
    background-color: #F4ACB7;
    height: 100%;
}

#root {
    height: 100%;
}

.chinese {
    font-family: "Noto Sans HK", "San Francisco";
}

.bold {
    font-weight: 700;
}

.swiper-button-prev {
    color: black;
}

// Utility Class
.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: grey;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 2;

}

body {
    margin: 0px;
    padding: 0px;
    height: 100%;
}

.header-1 {
    font-size: 28px;
    font-weight: 700;
}

.header-2 {
    font-size: 24px;
    font-weight: 700;
}

.header-3 {
    font-size: 20px;
    font-weight: 700;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

