.WorkshopCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
        transition: background-color 0.3s ease;
    &:hover {
        background-color: #FFCAD4;
    }
    .img-container {
        height: 200px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .text-container {
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        .title {
            font-size: 24px;
            font-weight: 600;
        }
        .details {
            font-size: 20px;
        }
        .hosted-container {
            font-size: 18px;
        }
    }

}