@use "./Breakpoints" as *;
@use "./Font" as *;

.job-details-container,
.workshop-details-container {
    background-color: white;
    margin: 30px 50px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 30px;
    position: relative;
    @include md {
        margin: 30px 30px;
    }

    @include sm {
        margin: 30px 20px;
    }
    .apply-job-btn {
            position: absolute;
            bottom: 15px;
            right: 20px;
            border: 0;
            background-color: #FFCAD4;
            padding: 10px 50px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 18px;
    
            &:hover {
                background-color: #FF5D8F;
                color: white;
            }
        }

    .white-container {
        background-color: #fff;
        z-index: 3;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        border-radius: 20px;
        padding-bottom: 60px;
        @include md {
            width: 80%;
        }

        @include sm {
            width: 90%;
        }

        img {
            display: block;
            margin: auto;
            padding-bottom: 30px;
            width: 70px;
            height: 70px;
        }

        .body-text {
            font-size: $font-sm;
            font-weight: 600;
        }

        .button-container {
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-top: 30px;

            button {
                border: 0;
                min-width: 150px;
                padding: 5px 40px;
                border-radius: 7px;
                color: white;
                font-size: 18px;
                font-weight: 600;

                &:nth-child(1) {
                    background-color: #9D8189;
                }

                &:nth-child(2) {
                    background-color: #F4ACB7;
                }

                &:hover {
                    background-color: #FF5D8F;
                }
            }
        }

        .title {
            font-size: $font-md;
            font-weight: 700;
            text-align: center;
            padding-top: 40px;
            padding-bottom: 30px;

        }

        .body-text {
            text-align: center;
        }
    }


    .h1 {
        font-size: 30px;
        font-weight: 600;
    }

    .h2 {
        font-size: 28px;
        font-weight: 600;
    }

    .h3 {
        font-size: $font-sm;
    }

    .top-container {
        display: flex;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
        align-items: center;
        column-gap: 10px;

        button {
            background-color: #343A40;
            border-radius: 50%;
            border: none;
            height: 42px;
            width: 42px;
            color: white;
        }
    }

    .job-container,
    .details-container {
        display: flex;
        justify-content: space-between;
        column-gap: 50px;
        @include sm {
            column-gap: 0;
        }

        .summary-item,
        .allowance-item,
        .requirement-item {
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-size: $font-sm;

            .icon {
                font-size: 30px;
                width: 37px;
            }
            .text {
                width: 70%;
            }

        }

        .left {
            width: 65%;
            display: flex;
            flex-direction: column;
            row-gap: 50px;
            @include lg {
                width: 100%;
            }

            .job-summary {
                margin-top: 20px;
            }
            .job-detail {
                .detail-text {
                    font-size: $font-sm;
                }
                @include sm {
                    padding-bottom: 50px;
                }
            }

          .allowance {
            @include sm {
                padding-bottom: 40px;
            }
          }
            .allowance-item {
                width: 33%;
            }

            .summary-items {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                row-gap: 10px;
                @include sm {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 20px;
                }

            }

            .dress-code {
                .img-container {
                    height: 200px;

                    swiper-container {
                        height: 100%;
                    }

                    swiper-slide {
                        display: flex;
                        justify-content: center;
                        height: 95%;

                    }

                    img {
                        height: 100%;
                        border: 1px solid black;

                    }
                }
            }
        }

    }
}

.swiper-button-prev {
    color: black;
}