 @use "./Breakpoints" as *;
 @use "./Font" as *;

 .profile-container {
     width: 90%;
     margin: auto;
     padding-top: 30px;
     display: flex;
     column-gap: 30px;

     @include md {
         flex-direction: column;
         row-gap: 30px;
     }

     .EmployeeReviewComponent {
         background-color: #fff;
         flex-grow: 1;
         border-radius: 15px;
         box-sizing: border-box;
         padding: 20px 0 40px 0;

         .review-card-container {
             display: flex;
             flex-direction: column;
             align-items: center;
             row-gap: 20px;
             margin-top: 20px;

             .EmployeeReviewCard {
                 width: 90%;
                 background-color: #FFCAD4;
                 border-radius: 15px;
                 padding: 25px;
                 box-sizing: border-box;

                 .top-container {
                     display: flex;
                     column-gap: 10px;

                     .img-container {
                         width: 60px;
                         height: 60px;
                         display: flex;
                         overflow: hidden;
                         justify-content: center;
                         align-items: center;
                         border-radius: 10px;

                         img {
                             width: 100%;
                             height: 100%;
                             object-fit: cover;
                         }
                     }

                     .text-container {
                         .title {
                             padding: 0;
                         }

                         .bottom-text-container {
                             display: flex;
                             column-gap: 10px;

                             .date {
                                 font-size: 18px;
                                 margin-top: 5px;
                             }

                             .StarsDisplay {
                                 font-size: 20px;
                             }

                             .star {
                                 color: #FFF;

                                 &.filled {
                                     color: #000;
                                 }
                             }
                         }
                     }
                 }

                 .compliments-container {
                     display: flex;
                     column-gap: 10px;
                     margin-top: 10px;
                     row-gap: 10px;
                     flex-wrap: wrap;

                     .compliment {
                         background-color: #fff;
                         padding: 5px 20px;
                         border-radius: 100px;
                     }

                 }

                 .review-container {
                     margin-top: 10px;
                 }
             }
         }
     }

     .title {
         font-size: 28px;
         font-weight: 600;
         text-align: center;
         padding: 0px 0 10px 0;
     }

     .sub-title {
         font-size: 20px;
         text-align: center;
     }

 }