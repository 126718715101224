// Landing starts here
@use './Landing.scss';
@use './Utility.scss';
@use './Navbar.scss';
@use './Homepage.scss';
@use './JobDetails.scss';
@use './SignUp.scss';
@use './UserProfile.scss';
@use './LanguageModule.scss';
@use './ViewCandidates.scss';
@use './Font.scss';
@use './CKEditor.scss';
@use './Dropdown.scss';
@use './EmployerReview.scss';
@use './UserProfileCard.scss';
@use './UserReviews.scss';
@use './WorkshopCard.scss';
@use './WorkshopDetails.scss';
@use './SignUpEmployeeNew.scss';

.App {
  padding-bottom: 15px;
}