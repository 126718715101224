@use "./Breakpoints" as *;

.view-candidate-employer {
    .view-candidates-component {
        padding-top: 30px;
        border-radius: 10px;
        width: 90%;
        margin: auto;
        .background-container {
            background-color: #fff;
            padding: 30px 0;
            .title {
                margin-left: 9%;
                font-weight: 700;
                font-size: 24px;
                padding-bottom: 10px;
            }
            .component-container {
                padding-bottom: 10px;
                .left {
                    display: flex;
                }
                .button-container {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                        .details-container {
                                background-color: #fff;
                                padding: 10px 30px;
                                border-radius: 100px;
                                font-weight: 700;
                                font-size: 18px;
                                &:hover {
                                    background-color: #FF5D8F;
                                    color: #fff;

                                }
                            }
                    .icon-container {
                        border-radius: 50%;
                        background-color: #fff;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 30px;
                        color: red;
                        &.tick {
                            color: green;
                        }
                        &:hover {
                            background-color: #FF5D8F;
                            color: #fff;
                        }

                    }
                }
               .component {
                    background-color: #FFCAD4;
                    width: 80%;
                    display: flex;
                    padding: 10px 20px;
                    margin: auto;
                    border-radius: 13px;
                    justify-content: space-between;
                    .img-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 10px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                        }
                    }
                    .text-container {
                        display: flex;
                        flex-direction: column;
                        padding-left: 20px;
                        .name {
                            display: flex;
                            align-items: center;
                            height: 100%;
                            font-size: 24px;
                            font-weight: 600;
                        }
                    }
               }
            }
        }
    }
}



// View-Candidates in detail, this is for the new page
.view-candidate {
    .grid-container {
        max-width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 2fr 5fr;
        column-gap: 30px;
        padding-top: 30px;
        row-gap: 30px;
        grid-template-rows: auto;
        .selected-jobs-component {
            grid-column: 1 / 2;
            @media (max-width: 800px) {
                grid-column: 1 / 3;
            }
        }
        .selected-job-container {
            background-color: #fff;
            
            overflow: hidden;
            box-sizing: border-box;
            padding: 15px 10px 20px 10px;
            border-radius: 10px;
            height: max-content;
            .title {
                font-size: 30px;
                font-weight: 600;
                width: 95%;
                margin: auto;
            }
            .job-listing-container {
                display: flex;
                column-gap: 20px;
                border: 3px solid #F4ACB7;
                padding: 10px;
                border-radius: 10px;
                margin-top: 10px;
                @include md {
                    flex-direction: column;
                }
                @media (max-width: 800px) {
                    flex-direction: row;
                }
                .text-container { 
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .job-title {
                        font-size: 24px;
                        font-weight: 500;

                    }
                    .information {
                        font-size: 20px;
                    }
                }
            }
            .img-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                overflow: hidden;
                border-radius: 10px;
                flex: 0 0 50px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .confirmed-workers-container, .candidates-container {
            background-color: #fff;
            border-radius: 10px;
            .title {
                font-size: 30px;
                font-weight: 600;
                width: 95%;
                padding: 15px 0;
                margin: auto;
            }
            .worker-container {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                padding-bottom: 25px;
            }
            .worker-listing-item{
                display: flex;
                background-color: #F4ACB7;
                width: 95%;
                margin: auto;
                box-sizing: border-box;
                padding: 10px 20px;
                align-items: center;
                border-radius: 10px;
                .img-container {
                    display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 10px;
                    
                        img {
                            width: 100%;
                        }
                }
                .name-container {
                    font-size: 20px;
                    font-weight: 600;
                    padding-left: 15px;
                }

               .candidate-anchor {
                margin-left: auto;
               }

                .view-details-btn {
                    margin-left: auto;
                    border: 0;
                    background-color: #fff;
                    box-sizing: border-box;
                    padding: 5px 15px;
                    font-size: 16px;
                    border-radius: 100px;
                    font-weight: 600;
                    transition: background-color 0.3s ease;
                    &:hover {
                        background-color: #FF5D8F;
                        color: white;
                    }
                }
            }
        }
        .confirmed-workers-container {
            @media (max-width: 800px) {
                grid-column: 1/3;
            }
        }
        .candidates-container {
            grid-column: 2/3;
            background-color: #fff;
            @media (max-width: 800px) {
                grid-column: 1/3;
            }
        }
    }
}

.view-candidate-details {
    .grid-container {
            max-width: 90%;
            margin: auto;
            display: grid;
            grid-template-columns: 2fr 5fr;
            column-gap: 30px;
            padding-top: 30px;
            row-gap: 30px;
            @media (max-width: 1000px) {
                grid-template-columns: 1fr 1fr;
            }
    
            .selected-job-container {
                background-color: #fff;
                grid-column: 1 / 2;
                overflow: hidden;
                box-sizing: border-box;
                padding: 15px 10px 20px 10px;
                border-radius: 10px;
                height: max-content;
    
                .title {
                    font-size: 30px;
                    font-weight: 600;
                    width: 90%;
                }
    
                .job-listing-container {
                    display: flex;
                    column-gap: 20px;
                    border: 3px solid #F4ACB7;
                    padding: 10px;
                    border-radius: 10px;
                    margin-top: 10px;
    
                    .text-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
    
                        .job-title {
                            font-size: 24px;
                            font-weight: 500;
    
                        }
    
                        .information {
                            font-size: 20px;
                        }
                    }
                }
    
                .img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    border-radius: 10px;
                    flex: 0 0 50px;
    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
}

.view-candidate-details {
    .grid-container {
        grid-template-rows: auto 1fr;
      
    }
    .selected-jobs-component {
        @media(max-width: 700px) {
            grid-column: 1 / 3;
        }
    }
    .profile-container {
        grid-row: span 2;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        height: max-content;
        @media (max-width: 1000px) {
            grid-column: 1 /3 ;
            grid-row: auto;
            }
        .top-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
            .img-container {
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 150px;
                height: 150px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
               
            }
            .name {
                font-size: 40px;
                font-weight: 600;
            }
        }
        .mid-container {
            display: flex;
            margin-top: 60px;
            column-gap: 30px;
            @media (max-width: 800px) {
                flex-direction: column;
                row-gap: 30px;
                align-items: center;
            }
            .left {
                width: 50%;
                @media (max-width: 800px) {
                        width: 100%;
                    }
                .title {
                    font-size: 28px;
                    font-weight: 600;
                    text-align: center;
                    padding-bottom: 15px;
                }
                .best-five-container {
                    display: flex;
                    column-gap: 10px;
                    row-gap: 10px;
                    justify-content: center ;
                    flex-wrap: wrap;
                    .best-five-item {
                        background-color: #F4ACB7;
                        padding: 5px 25px;
                        font-size: 22px;
                        border-radius: 100px;

                    }
                }
            }
            .right {
                width: 50%;
                @media (max-width: 800px) {
                       width: 100%;
                    }
                .title {
                    font-size: 28px;
                    font-weight: 600;
                    text-align: center;
                    padding-bottom: 15px;
                }
                .past-jobs-container {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    
                    .past-job-item {
                        display: flex;
                        background-color: #F4ACB7;
                        align-items: center;
                        border-radius: 10px;
                        padding: 10px;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "";
                            width: 1px;
                            height: 65%;
                            border-right: 3px solid black;
                            left: 50%;
                        }
                        .job-title, .date-container {
                            width: 50%;
                            padding-left: 20px;
                        }
                        .job-title {
                            font-size: 24px;
                          
                        }
                        .date-container {
                            font-size: 20px;
                            .sub-title {
                                font-size: 24px;
                                font-weight: 600;
                                padding-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
        .bottom-container {
            display: flex;
            justify-content: center;
            column-gap: 30px;
            margin-top: 80px;
            button {
                border: 0;
                padding: 10px 50px;
                border-radius: 100px;
                font-size: 20px;
                
            }
            .reject {
                background-color: #F4ACB7;
                font-weight: 600;
                transition: background-color 0.3s ease;
                &:hover {
                    background-color: #FF5D8F;
                    color: #fff;
                }
            }

            .accept {
                background-color: #8DFFAD;
                font-weight: 600;
                transition: background-color 0.3s ease;
                &:hover {
                    background-color: #14c400;
                    color: #fff;
                }
            }
        }
    }
    .candidates-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        @media (max-width: 1000px) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }
        @media (max-width: 700px) {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }
       
        .title {
            font-size: 30px;
            font-weight: 600;
        }
        .candidate-item {
            display: flex;
            background-color: #fff;
            border-radius: 10px;
            padding: 15px 25px;
            align-items: center;
            column-gap: 15px;
            font-size: 24px;
            box-sizing: border-box;
            &:hover {
                box-shadow: 0 0 10px 3px #54c7f8;
            }
            .img-container {
                display: flex;
                width: 60px;
                height: 60px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }
        }
    }
}