@use "./Breakpoints" as *;
.dropdown {
    position: relative;
    display: flex;
    width: 100%;
    height: 41px;
    align-items: center;
    &::before {
        content: ">";
        position: absolute;
        right: 15px;
        transform: translateY(-50%) rotate(90deg) scaleY(1.5) scaleX(0.7);
        font-size: 24px;
        top: 50%;
        pointer-events: none;
    }
    .dropdown-title {
        width: 100%;
        background-color: #fff;
        padding: 5px !important;
        font-size: 20px;
        border: 1px solid black;
    }
}


.dropdown-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 39px;
    background-color: #FF8FAB;
    border: 1px solid #000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 99%;
    z-index: 1;
}

.dropdown-item {
    padding: 10px !important;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #F4ACB7;
}


.submenu-item {
    padding: 10px 20px !important;
    background-color: #fff;
    cursor: pointer;
}

.submenu-item:hover {
    background-color: #F4ACB7;
}


.submenu-item.selected {
    background-color: #F4ACB7;
    /* Light green background */
}

.dropdown-buttons-container {
    button {
        border: 0px;
        box-sizing: border-box;
        padding: 7px 30px;
        border-radius: 24px;
        margin-left: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
        &:hover {
            background-color: #F4ACB7;
            color: #fff;
        }
    }
}