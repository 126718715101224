.SignUpEmployeeNew {
    background-color: #fff;
    max-width: 500px;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
    border-radius: 20px;
    width: 95%;

    .step-indicator {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        /* Adjust as needed */
    }

    .step-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        font-weight: bold;
    }

    .step-circle.active {
        background-color: #F4ACB7;
        /* Active step color */
        color: white;
    }

    .step-component {
        box-sizing: border-box;
        padding: 30px 20px;
        position: relative;
        min-height: 90vh;

        .button {
            padding: 5px 15px;
            border: none;
            background-color: #FFCAD4;
            border-radius: 100px;
        }

        &.blur {
            .dark-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 2;
                pointer-events: none;
            }
        }

        .past-job-entry {
            display: flex;
            background-color: #FFE2E9;
            flex-direction: column;
            box-sizing: border-box;
            padding: 10px 20px 20px 20px;
            border-radius: 15px;
            row-gap: 5px;

            .one {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .employment-type {
                    background-color: #FFCAD4;
                    padding: 5px 10px;
                    border-radius: 10px;
                }
            }

            span {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .employment-type-options {
            display: flex;
            justify-content: space-around;

            .employment-type {
                background-color: #FFCAD4;
                padding: 5px 10px;
                border-radius: 7px;

                &.selected {
                    background-color: #F4ACB7;
                    color: white;
                }
            }
        }

        .past-job-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 15px;
            max-width: 300px;
            width: 90%;
            z-index: 3;
            box-sizing: border-box;
            padding: 20px;
            height: 80vh;

            .overlay-content {
                h3 {
                    margin: 0;
                }

                div {
                    margin-top: 15px;

                    .employment-type {
                        margin-top: 0;
                    }

                    textarea {
                        width: 100%;
                    }
                }
            }

            .button-container {
                button {
                    padding: 10px 30px;
                }

                .job-button {
                    width: 50%;
                    padding: 10px 0;
                }
            }
        }

        .past-job-container {
            display: flex;
            flex-direction: column;
            row-gap: 15px;

        }

        .add-job-button {
            width: 100%;
            background-color: #FFCAD4;
            border: none;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 15px;
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .option-item,
        .language-option {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            label {
                width: 90%;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                align-items: center;
            }

            input {
                width: auto;
            }

            input[type="checkbox"] {
                display: none;
            }

            /* Style the label to look like a checkbox */
            .custom-checkbox {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                /* Make it circular */
                border: 2px solid #ccc;
                /* Border color */
                display: inline-block;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s, border-color 0.3s;
            }

            /* Change the background and border color when checked */
            input[type="checkbox"]:checked+.custom-checkbox {
                background-color: pink;
                /* Background color when checked */
                border-color: pink;
                /* Border color when checked */
            }

            /* Optional: Add a checkmark */
            .custom-checkbox::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background: white;
                /* Checkmark color */
                border-radius: 50%;
                /* Make checkmark circular */
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                /* Initially hidden */
                transition: transform 0.3s;
            }

            /* Scale the checkmark when checked */
            input[type="checkbox"]:checked+.custom-checkbox::after {
                transform: translate(-50%, -50%) scale(1);
                /* Show checkmark */
            }
        }

        .option-overlay {
            background-color: #FFF;
            border-radius: 15px;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 90%;
            transform: translate(-50%, -50%);
            z-index: 5;

            .overlay-content {
                padding: 30px;
                box-sizing: border-box;

                h3 {
                    margin: 0;
                    padding-bottom: 10px;
                }
            }
        }

        .blur {
            filter: blur(2px);
            transition: filter 0.3s ease;

        }

        .title {
            font-size: 30px;
            font-weight: 600;
        }

        .sub-title {
            font-size: 20px;
            font-weight: 600;
        }

        .item-1,
        .item-2 {
            margin-top: 30px;

            .tags-display {
                display: flex;
                flex-wrap: wrap;
                row-gap: 10px;
                column-gap: 10px;
                margin: 10px 0 10px 0;

                .tag {
                    background-color: #FFCAD4;
                    padding: 5px 15px;
                    border-radius: 100px;
                }
            }

            button {
                background-color: #FFCAD4;
                border: none;
                padding: 5px 30px;
                border-radius: 100px;
            }
        }

        .input-field-item {
            margin-top: 40px;
        }

        .bottom-container {
            bottom: 20px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        input {
            width: 100%;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            padding: 5px 15px;
            border-radius: 5px;
            border: 2px solid #D9D9D9;
        }

        .language-toggle {
            box-sizing: border-box;
            padding: 5px 15px;
            border-radius: 5px;
            border: 2px solid #D9D9D9;
        }

        .language-option {
            display: flex;
            margin-top: 15px;

            label {
                width: 100%;
            }

            input {
                width: auto;
            }
        }

        .language-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white;
            padding: 20px;
            width: 90%;
            max-width: 300px;
            box-sizing: border-box;
            height: 80%;
            border-radius: 8px;
            z-index: 2;
        }

        .button-container {
            display: flex;
            justify-content: center;
            column-gap: 40px;
            margin-top: 30px;

            button {
                background-color: #FFCAD4;
                border: 0;
                border-radius: 100px;
                box-sizing: border-box;
                padding: 5px 35px;
                transition: background-color 0.2s ease;
                width: max-content;

                &:hover {
                    background-color: #F4ACB7;
                }
            }
        }
    }
}