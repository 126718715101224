// @use "./Breakpoints" as *;

$breakpoints : (
    "sm" : 780px,
    "md" : 1024px,
    "lg" : 1280px,
    "xl" : 1380px
);

@mixin sm {
    @media (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin md {
    @media (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin lg {
    @media (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin xl {
    @media (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

