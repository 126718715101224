@use "./Breakpoints" as *;

.landing {
    .highlight {
        color: brown;
    }
    .English {
        font-size: 20;
        color: blue
    }

    .Chinese {
        font-size: 20;
        color: red
    }

    .Employer {
        font-size: 20;
        color: orange
    }

    .Worker {
        font-size: 20;
        color: green
    }

    .body {
        font-size: 20;
        font-weight: 500;
        color: black;
        background-color: white;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .button-box-language-gap {
        height: 25px;
        width: 20%;
        background-color: transparent;
    }

    .button-box-language {
        width: 100px;
        margin: auto;
        position: relative;
        box-sizing: border-box;
        float: right;
        z-index: 3;
        background-color: grey;
        border-radius: 30px;
        margin-left: 10px
    }

    .toggle-btn-language {
        width: 50px;
        padding: 10px 10px;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
        position: relative;
        text-align: center;
        color: white;
        font-weight: bold;
    }

    .button-box-type {
        width: 210px;
        height: 39px;
        margin: auto;
        position: relative;
        box-sizing: border-box;
        float: right;
        z-index: 3;
        background-color: grey;
        border-radius: 30px;
    }

    .button-border-box {
        flex-direction: row;
        box-sizing: border-box;
        justify-content: space-between;
        /* Set the navbar to fixed position */
        top: 30px;
        right: 10px;
        z-index: 3;
     
    }

    .mobile-button-border-box {
        display: none;
        @include sm {
            display: block;
        }
    }

    .toggle-btn-type {
        width: 100px;
        padding: 10px 10px;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
        text-align: center;
        position: relative;
        color: white;
        font-weight: bold;
        // vertical-align: center;
    }


    #btnLang {
        Left: 0;
        top: 0;
        position: absolute;
        width: 50px;
        height: 39px;
        background: rgb(248, 229, 232);
        border-radius: 30px;
        transition: .5s;
        border-color: black;
    }

    #btnType {
        Left: 0;
        top: 0;
        position: absolute;
        width: 110px;
        height: 39px;
        background: rgb(248, 229, 232);
        border-radius: 30px;
        transition: .5s;
        border-color: black;

    }

    .navbar {
        overflow: hidden;
        background-color: pink;
        position: fixed;
        /* Set the navbar to fixed position */
        top: 0;
        /* Position the navbar at the top of the page */
        width: 100%;
        /* Full width */
        height: 100px;
        z-index: 2;
    }

    .flex-container-banner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 50px;
        box-sizing: border-box;
        position: relative;
        z-index: 4;
        height: 100%;
        align-items: center;
        @include sm {
            justify-content: flex-end;
            padding-right: 20px;
        }
    }

    .flex-container-banner-1 {
        color: white;
        @include sm {
            z-index:4;
        }
        .desktop-menu {
            display: flex;
            align-items: center;
            padding-right: 30px;
            @include sm {
                display: none;
            }
        }
        .ham-menu {
            display: none;
            span {
                display: none;
                // Hiding it for demo
                // display: block;
                background-color: white;
                width: 100%;
                height: 5px;
            }
            @include sm {
                display: none;
                // Hiding it for demo
                // display: flex;
                position: absolute;
                left: 30px;
                top: 35px;
                justify-content: space-between;
                flex-direction: column;
                height: 30px;
                width: 50px;
            }
        }
        .off-screen-toggle {
            position: fixed;
            &.active {
                width: 100vh;
                height: 100vh;
                z-index: 4;
            }
            &.inactive {
                width: 0;
                height: 0;
            }
                         .cross {
                             position: absolute;
                             width: 50px;
                             height: 50px;
                             top: 50px;
                             left: 20px;
                                   
                            
                             span {
                                position: absolute;
                                border-radius: 15px;
                                display: block;
                                width:100%;
                                height:5px;
                                background-color: white;
                               
                             }
                             .cross-1 {
                                transform: rotate(45deg);
                             }
                             .cross-2 {
                                transform: rotate(-45deg);
                             }
            
                         }
        }
        .off-screen-menu {
            display: flex;
            background-color: rgba(147, 225, 216, 0.99);
            height: 100vh;
            width: 80%;
            max-width: 450px;
            position: fixed;
            top: 0;
            left: -450px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            transition: .3s ease;

            &.active {
               left: 0px;
            }
           
          
        }
    }

    .flex-container-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 5%;
        margin-top: 60px;
        @include md {
            flex-direction: column;
            align-items: center;
            row-gap: 50px;
        }
        &.reverse-column {
             @include sm {
                 flex-direction: column-reverse;
             }
         }
       
    }

    .flex-container-main-small {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .content-word-box {
        box-sizing: border-box;
        flex-direction: row;
        flex-wrap: wrap;
        /* padding: 50px 0px; */
        padding-top: 60px;
        padding-bottom: 50px;
        width: 500px;
        height: 450px;
        @include md {
            padding: 0;
            padding-top: 50px;
            width: 80%;
            height: auto;
        }
    }

    .content-word-box-small {
        box-sizing: border-box;
        flex-direction: column;
        flex-wrap: wrap;
        /* padding: 50px 0px; */
        width: 300px;
    }

    .flex-container-words {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        position: center;
        /* margin-left: 300px;
      margin-right: 500px; */
        justify-content: center;
    }

    .flex-container-words-content-4 {
        width: 100%;
        display: flex;
        justify-content: center;

    }


    .word-banner {
        font-size: 20px;
        font-weight: bold;
        padding: 25px 20px;
        display: none;
    }

    .main-header {
        font-size: 30px;
        box-sizing: border-box;
    }

    .main-header-small {
        font-size: 30px;
        box-sizing: border-box;
    }

    .main-body {
        font-size: 20px;
    }

    .main-body-small {
        font-size: 20px;
        width: 100px;
    }

    .main {
        font-size: 50px;
        padding: 50px 50px
    }

    .logo {
        width: 100px;
        height: 100px;
        @include sm {
            display: none;
        }
    }

    .Log-in-sign-up {
        background-color: pink;
        width: 150px;
        height: 50px;
        border-radius: 8px;
        z-index: 1;
        cursor: pointer;
    }

    .Log-in-sign-up:hover {
        background-color: rgb(228, 126, 143)
    }

    .platformTemplate {
        box-sizing: border-box;
        width: 550px;
        height: 400px;
        padding-top: 60px;
        float: right;
        justify-content: center;
        @include md {
            padding: 0;
            padding-bottom: 40px;
            height: auto;
            width: 70%;

        }
    }

    .main-2 {
        background-color: pink;
        width: 100%;
        height: 500px;
        @include lg {
            height: auto;
        }
        .flex-container-main-2{
            display: flex;
            color: white;
            width: 80%;
            justify-content: space-between;
            padding-bottom: 40px;
            @include sm {
                flex-direction: column;
            }
        }
    }

    .main-3 {
        width: 100%;
        height: auto;
        padding: 30px 0 100px 0;
        @include lg {
            padding-top: 10px;
            padding-bottom: 50px;
        }
    }

    .main-4 {
        background-color: pink;
        width: 100%;
        padding: 50px 0 70px 0;
    }

    .main-header-2 {
        /* width:1440px; */
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: white
    }

    .main-header-3 {
        /* width:1440px; */
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: black
    }

    .main-header-4 {
        /* width:1440px; */
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: white
    }

    .main-header-2-highlight {
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: brown;
    }

    .main-header-3-highlight {
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: brown;
    }

    .main-header-4-highlight {
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: brown;
    }


    .flex-container-main-2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 60px;
        box-sizing: border-box;
        width: 80%;
        margin: auto;
        color: white;
        justify-content: center;
        @include lg {
            grid-template-columns: 1fr 1fr;
        }
        @include sm {
            grid-template-columns: 1fr;
            width: 50%;
        }
        .main-3-content {
            color: black;
            @include lg {
                width: 80%;
                justify-self: center;
            }
        }
    }

    .main-4 {
        .grid-container {
            display: grid;
            grid-template-columns: 1fr 3fr;
            @include sm {
                grid-template-columns: 1fr;
            }
            img {
                padding: 0;
                justify-self: center;
                align-self: center;
            }
        }
        .content-4 {
            display: flex;
            justify-content: center;
            column-gap: 15%;
            padding-left: 70px;
            @include sm {
                flex-direction: column;
                padding-left: 0;
                align-items: center;
            }
        }
        .main-4-content {
            width: 350px;
            @include lg {
                width: 40%;
            }
            @include sm {
                width: 80%;
            }
        }
    }

    .flex-container-words {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        position: center;
        margin-bottom: 30px;
        /* margin-left: 300px;
      margin-right: 500px; */
        justify-content: center;
    }

    .main-2-image {
        width: auto;
        height: 150px;
        fill: white;
    }

    .image-flexible-job {
        width: auto;
        height: 175px;
        padding-bottom: -20px;
        fill: white;
        position: center;
    }

    .main-content-1A {
        margin-top: -10px;
    }

    .main-2-content {
        /* box-sizing: border-box; */
        text-align: center;
    }

   


    .main-2-employer {
        background-color: white;
        width: 100%;
        height: 100px
    }

    .main-header-2-employer {
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: black
    }

    .main-header-2-highlight-employer {
        box-sizing: border-box;
        padding: left 300px;
        padding-top: 20px;
        padding-right: 10px;
        text-align: center;
        color: brown;
    }

    .main-header {
        font-size: 30px;
        box-sizing: border-box;
    }

    .main-4-content-header-left {
        color: grey;
        padding-left: 0px;
    }

    .main-4-content-header-right {
        color: grey;
        padding-left: 0px;
    }

    .main-4-content-content-left {
        font-size: 20px;
        width: 350px;
        @include lg {
            margin-right: 0;
            width: auto;
        }
    }

    .main-4-content-content-right {
        font-size: 20px;
    }

    .main-4-image {
        width: 200px;
        height: 200px;
        fill: white;
        padding: 150px 100px 0px 150px;
        margin-right: -50px;
        @include lg {
            margin-right: 0;
        }
    }

    .main-content-3-image {
        font-size: 6em;
        color: brown;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .main-content-3-content {
        font-size: 1.5em;
    }
}