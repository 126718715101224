@use "./Breakpoints" as *;

.nav {
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
    align-items: center;
    border-radius: 15px;
    padding: 15px 30px;
    box-sizing: border-box;
    margin: auto;
    margin-top: 40px;

    .left {
        display: flex;
        align-items: center;
        position: relative;
        .ham-menu {
                display: none;
               
                span {
                    display: block;
                    background-color: black;
                    width: 100%;
                    height: 4px;
                }
        
                @include sm {
                    display: flex;
                    position: absolute;
                    top: 7px;
                    left: 60px;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 30px;
                    width: 50px;
                }
            }
        // Mobile Menu CSS
        .off-screen-menu {
            display: flex;
            background-color: rgba(147, 225, 216, 0.99);
            height: 100vh;
            width: 80%;
            max-width: 450px;
            position: fixed;
            top: 0;
            left: -450px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            transition: .3s ease;
            color: white;

            &.active {
                left: 0px;
                z-index: 5;
            }
            .cross {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50px;
                left: 20px;


                span {
                    position: absolute;
                    border-radius: 15px;
                    display: block;
                    width: 100%;
                    height: 5px;
                    background-color: white;

                }

                .cross-1 {
                    transform: rotate(45deg);
                }

                .cross-2 {
                    transform: rotate(-45deg);
                }

            }


        }
            .nav-item-wrapper {
                display: flex;

                @include sm {
                    display: none;
                }
            }


        img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
        
        .nav-item {
            padding: 0 15px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                right: 0;
                width: 1px;
                height: 80%;
                top: 15%;
                border-right: 2px solid #d4d4d4;
                @include sm {
                    display: none;
                }
            }
            &:last-child {
                &::before {
                    border: 0;
                }
            }

        }
    }

    .nav-item {
        font-weight: 600;
        font-size: 24px;

        &:hover {
            color: #FF8FAB;
        }
    }

}