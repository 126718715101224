@use "./Breakpoints" as *;

.EmployerReview {
    .grid-container {
        max-width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 2fr 5fr;
        column-gap: 30px;
        padding-top: 30px;
        row-gap: 30px;
        @include md {
            grid-template-columns: 1fr;
        }
    }
  
}

.selected-job-container {
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px 10px 20px 10px;
    border-radius: 10px;
    height: max-content;
    .title {
        font-size: 30px;
        font-weight: 600;
        width: 90%;
    }
    .job-listing-container {
         display: flex;
        column-gap: 20px;
        border: 3px solid #F4ACB7;
        padding: 10px;
        border-radius: 10px;
        margin-top: 10px;
        .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 10px;
            flex: 0 0 50px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .job-title {
                font-size: 24px;
                font-weight: 500;
            }
            .information {
                font-size: 20px;
            }
        }
    }
}


.ReviewComponent {
    grid-row: span 2;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    height: max-content;
}


.EmployerReview {
    .top-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .img-container {
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name {
            font-size: 40px;
            font-weight: 600;
        }
    }
    .candidates-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .title {
            font-size: 30px;
            font-weight: 600;
        }
        .candidate-item {
            display: flex;
            background-color: #fff;
            border-radius: 10px;
            padding: 15px 25px;
            align-items: center;
            column-gap: 15px;
            font-size: 24px;
            box-sizing: border-box;
            transition: box-shadow 0.3s ease;
            &:hover {
                box-shadow: 0 0 10px 3px #54c7f8;
            }
            .img-container {
                display: flex;
                width: 60px;
                height: 60px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
             }
        }
    }
}

.ReviewComponent {
    
    .sub-title {
        font-size: 24px;
        font-weight: 600;
    }
    .compliment-wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        margin: 10px 0;
        .compliment {
            background-color: #F4ACB7;
            padding: 5px 25px;
            font-size: 22px;
            border-radius: 100px;
            transition: background-color 0.2s ease, color 0.2s ease;
            &:hover {
                background-color: #FF8FAB;
                color: white;
            }
            &.selected {
                background-color: #FF8FAB;
                color: white;
            }
        }
    }
    textarea {
        border: 0px;
        background-color: #ccc;
        width: 100%;
        min-height: 19vh;
        border-radius: 15px;
        vertical-align: top;
        padding: 10px 15px;
        box-sizing: border-box;
        margin: 10px 0;
    }

    .bottom-container {
        display: flex;
        .star-rating-container {
            width: 50%;
        }
        .submit-review-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .confirm-button {
            border: 0;
            background-color: #8DFFAD;
            border-radius: 100px;
            font-size: 19px;
            font-weight: 600;
            padding: 5px 30px;
            transition: background-color 0.3s ease;
            width: max-content;
            &:hover {
                background-color: #228B22;
                color: #fff;
            }
        }
    }

    .star-rating {
            display: flex;
            cursor: pointer;
        }

        .star {
            font-size: 2rem;
            /* Adjust size as needed */
            color: #ccc;
            /* Default star color */
            transition: color 0.2s;
        }

        .star.filled {
            color: #FF8FAB;
            /* Color for filled stars */
        }
}